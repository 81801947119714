import React, { useEffect } from 'react';
import {
	Grid,
	Hidden,
	makeStyles,
	Box,
	Divider,
	Container,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFooterMenu,
	fetchFooterSub_Menu,
} from '../../reducers/HomeReducer';

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		// backgroundColor: 'rgb(0, 87, 94)',
		// marginTop: 10,
		paddingTop: 20,
		paddingBottom: 20,
		backgroundImage: `url(https://sarvartravels.com/img/footerbackimage.jpeg)`,
	},
	link: {
		textDecoration: 'none',
		color: '#C4C4C4',
	},
	SolutionDiv: {
		paddingRight: 20,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			marginTop: 30,
			paddingRight: 0,
			order: 2,
		},
	},
	ExploreDiv: {
		marginTop: 10,
		paddingRight: 20,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			marginTop: 20,
			order: 1,
			paddingRight: 0,
		},
	},
	ExploreinnerDiv: {
		paddingRight: 20,
		[theme.breakpoints.down('xs')]: {
			paddingRight: 0,
		},
	},
	logoDiv: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			marginTop: 20,
			order: 3,
		},
	},
	logoImage: {
		marginBottom: 40,
		marginTop: 50,
		[theme.breakpoints.down('md')]: {
			marginTop: 0,
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
		},
	},
}));

const Footer = () => {
	const classes = useStyles();
	const { footer_menu, footer_submenu } = useSelector((state) => state.home);
	const theme = useTheme();
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	useEffect(() => {
		let form = {};
		dispatch(fetchFooterMenu(form));
		dispatch(fetchFooterSub_Menu(form));
	}, []);
	let row = [];
	let rown2 = [];
	let rown3 = [];
	let rown4 = [];
	let rown5 = [];
	let rown6 = [];
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[0].id) {
				row.push({
					...item,
				});
			}
		});
	}
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[1].id) {
				rown2.push({
					...item,
				});
			}
		});
	}
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[2].id) {
				rown3.push({
					...item,
				});
			}
		});
	}
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[3].id) {
				rown4.push({
					...item,
				});
			}
		});
	}
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[4].id) {
				rown5.push({
					...item,
				});
			}
		});
	}
	if (footer_menu && footer_submenu) {
		footer_submenu?.result?.map((item, index) => {
			if (item.parentid === footer_menu?.result[5].id) {
				rown6.push({
					...item,
				});
			}
		});
	}
	return (
		<>
			<Hidden xlUp>
				{/* <Box style={{ background: '#17407dfa' }}>
					<Container>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							padding='10px'>
							<Box>
								<h4 style={{ color: 'white' }}>Stay Connected With Us</h4>
							</Box>
							<Hidden only={['xs', 'sm']}>
								<Box
									display='flex'
									justifyContent='space-between'
									alignItems='center'>
									<Box
										style={{
											background: 'white',
											padding: 5,
											cursor: 'pointer',
											borderRadius: 25,
										}}
										alignItems='center'>
										<i class='fab fa-facebook-f' style={{ color: 'blue' }}></i>
										<span
											style={{
												marginLeft: 5,
												fontFamily: '"Poppins", sans-serif',
												fontSize: 12,
											}}>
											Facebook
										</span>
									</Box>
									<Box
										style={{
											background: 'white',
											padding: 5,
											marginLeft: 10,
											cursor: 'pointer',
											borderRadius: 25,
										}}
										alignItems='center'>
										<i class='fab fa-twitter' style={{ color: '#1DA1F2' }}></i>
										<span
											style={{
												marginLeft: 5,
												fontFamily: '"Poppins", sans-serif',
												fontSize: 12,
											}}>
											Twitter
										</span>
									</Box>
									<Box
										style={{
											background: 'white',
											padding: 5,
											marginLeft: 10,
											cursor: 'pointer',
											borderRadius: 25,
										}}
										alignItems='center'>
										<i
											class='fab fa-linkedin-in'
											style={{ color: '#0A66C2' }}></i>
										<span
											style={{
												marginLeft: 5,
												fontFamily: '"Poppins", sans-serif',
												fontSize: 12,
											}}>
											Linkedin
										</span>
									</Box>
									<Box
										style={{
											background: 'white',
											padding: 5,
											marginLeft: 10,
											cursor: 'pointer',
											borderRadius: 25,
										}}
										alignItems='center'>
										<i
											class='fab fa-instagram'
											style={{ color: '#405DE6' }}></i>
										<span
											style={{
												marginLeft: 5,
												fontFamily: '"Poppins", sans-serif',
												fontSize: 12,
											}}>
											Instagram
										</span>
									</Box>
								</Box>
							</Hidden>
						</Box>
					</Container>
				</Box> */}
				<div
					className={classes.mainContainer}
					style={{
						paddingLeft: matches ? 30 : 50,
						paddingRight: matches ? 30 : 50,
					}}>
					{/* <Box style={{background:'#271545'}}>
					<Container>
						<Box>
							<Box><h3>Stay Connected With Us</h3></Box>
							<Box></Box>
						</Box>
					</Container>
				</Box> */}
					<Box
						style={{
							border: '1px solid #727272',
							borderRadius: '12px',
							padding: '7px 3px 3px 2px',
							textAlign: 'center',
						}}>
						<Grid container>
							<Grid item xs={1}></Grid>
							<Grid item xs={10} style={{ textAlign: 'center' }}>
								<h4
									style={{
										color: '#1a2b48 !important',
										fontFamily: '"Poppins", sans-serif',
										fontWeight: 600,
									}}>
									INDIA - SAUDI ARABIA - KUWAIT - MEDINA - MECCA - WASHINGTON -
									CHICAGO - HOUSTON
								</h4>
							</Grid>
							<Grid item xs={1}></Grid>
						</Grid>
					</Box>
					<Grid container style={{ padding: matches ? 20 : 40 }}>
						<Grid item xs={12} lg={2}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 700,
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									{footer_menu?.result[0]?.name}
								</h6>
								{row?.map((i) => {
									return i.link_url ? (
										<>
											<a href={i.link_url} target='_blank'>
												<span
													style={{
														color: '#1a2b48',
														fontSize: 10,
														fontFamily: '"Poppins", sans-serif',
														fontWeight: 500,
													}}>
													{i.name}
												</span>
											</a>
											<br />
										</>
									) : (
										<>
											<span
												style={{
													color: '#1a2b48',
													fontSize: 10,
													fontFamily: '"Poppins", sans-serif',
													fontWeight: 500,
													cursor: 'pointer',
												}}
												onClick={() =>
													history.push({
														pathname: `/footer/${i.link}`,
														state: i,
													})
												}>
												{i.name}
											</span>
											<br />
										</>
									);
								})}
							</Box>
						</Grid>
						<Grid item xs={12} lg={2} style={{ marginTop: matches ? 20 : 0 }}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 700,
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									{footer_menu?.result[1]?.name}
								</h6>
								{rown2?.map((i) => {
									return !i.content ? (
										<>
											<a href={i.link_url} target='_blank'>
												<span
													style={{
														color: '#1a2b48',
														fontSize: 10,
														fontFamily: '"Poppins", sans-serif',
														fontWeight: 500,
													}}>
													{i.name}
												</span>
											</a>
											<br />
										</>
									) : (
										<>
											<span
												style={{
													color: '#1a2b48',
													fontSize: 10,
													fontFamily: '"Poppins", sans-serif',
													fontWeight: 500,
													cursor: 'pointer',
												}}
												onClick={() =>
													history.push({
														pathname: `/online/${i.name}`,
														state: i,
													})
												}>
												{i.name}
											</span>
											<br />
										</>
									);
								})}
							</Box>
						</Grid>
						<Grid item xs={12} lg={2} style={{ marginTop: matches ? 20 : 0 }}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 700,
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									{footer_menu?.result[2]?.name}
								</h6>
								{rown3?.map((i) => {
									return !i.content ? (
										<>
											<a href={i.link_url} target='_blank'>
												<span
													style={{
														color: '#1a2b48',
														fontSize: 10,
														fontFamily: '"Poppins", sans-serif',
														fontWeight: 500,
													}}>
													{i.name}
												</span>
											</a>
											<br />
										</>
									) : (
										<>
											<span
												style={{
													color: '#1a2b48',
													fontSize: 10,
													fontFamily: '"Poppins", sans-serif',
													fontWeight: 500,
													cursor: 'pointer',
												}}
												onClick={() =>
													history.push({
														pathname: `/online/${i.name}`,
														state: i,
													})
												}>
												{i.name}
											</span>
											<br />
										</>
									);
								})}
							</Box>
						</Grid>
						<Grid item xs={12} lg={2} style={{ marginTop: matches ? 20 : 0 }}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 700,
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									{footer_menu?.result[3]?.name}
								</h6>
								{rown4?.map((i) => {
									return !i.content ? (
										<>
											<a href={i.link_url} target='_blank'>
												<span
													style={{
														color: '#1a2b48',
														fontSize: 10,
														fontFamily: '"Poppins", sans-serif',
														fontWeight: 500,
													}}>
													{i.name}
												</span>
											</a>
											<br />
										</>
									) : (
										<>
											<span
												style={{
													color: '#1a2b48',
													fontSize: 10,
													fontFamily: '"Poppins", sans-serif',
													fontWeight: 500,
													cursor: 'pointer',
												}}
												onClick={() =>
													history.push({
														pathname: `/online/${i.name}`,
														state: i,
													})
												}>
												{i.name}
											</span>
											<br />
										</>
									);
								})}
							</Box>
						</Grid>
						<Grid item xs={12} lg={2} style={{ marginTop: matches ? 20 : 0 }}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 700,
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									{footer_menu?.result[4]?.name}
								</h6>
								{rown5?.map((i) => {
									return !i.content ? (
										<>
											<a href={i.link_url} target='_blank'>
												<span
													style={{
														color: '#1a2b48',
														fontSize: 10,
														fontFamily: '"Poppins", sans-serif',
														fontWeight: 500,
													}}>
													{i.name}
												</span>
											</a>
											<br />
										</>
									) : (
										<>
											<span
												style={{
													color: '#1a2b48',
													fontSize: 10,
													fontFamily: '"Poppins", sans-serif',
													fontWeight: 500,
													cursor: 'pointer',
												}}
												onClick={() =>
													history.push({
														pathname: `/online/${i.name}`,
														state: i,
													})
												}>
												{i.name}
											</span>
											<br />
										</>
									);
								})}
							</Box>
						</Grid>
						<Grid item xs={12} lg={2} style={{ marginTop: matches ? 20 : 0 }}>
							<Box>
								<h6
									style={{
										color: '#1a2b48',
										fontWeight: 'bold',
										textTransform: 'uppercase',
										fontSize: 12,
									}}>
									PAYMENT & SECURITY
								</h6>
								<img
									src='https://marinersforex.com/images/pgicon.png'
									style={{ maxWidth: '70%' }}
								/>
								<h6
									style={{
										color: '#1a2b48',
										marginTop: 10,
										fontSize: 12,
										fontWeight: 'bold',
									}}>
									FOLLOW US ON
								</h6>
								<a
									style={{
										color: '#FFF',
										borderRadius: '6px',
										border: '1px solid #FFF',
										padding: '3px',
										background: '#89055a',
									}}
									// href='https://twitter.com/MarinersForex'
									target='_new'>
									<i
										class='fa fa-twitter'
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								<a
									style={{
										color: '#FFF',
										borderRadius: '6px',
										border: '1px solid #FFF',
										padding: '3px',
										background: '#89055a',
										marginLeft: 10,
									}}
									// href='https://www.facebook.com/marinersforexpvtltd'

									target='_new'>
									<i
										class='fa fa-facebook'
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								<a
									style={{
										color: '#FFF',
										borderRadius: '6px',
										border: '1px solid #FFF',
										padding: '3px',
										background: '#89055a',
										marginLeft: 10,
									}}
									// href='https://www.linkedin.com/company/79836916/admin/'

									target='_new'>
									<i
										class='fa fa-linkedin'
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								<a
									style={{
										color: '#FFF',
										borderRadius: '6px',
										border: '1px solid #FFF',
										padding: '3px',
										background: '#89055a',
										marginLeft: 10,
									}}
									href='https://www.instagram.com/'
									target='_new'>
									<i
										class='fa fa-instagram'
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								<a
									style={{
										color: '#FFF',
										borderRadius: '6px',
										border: '1px solid #FFF',
										padding: '3px',
										background: '#89055a',
										marginLeft: 10,
									}}
									// href='https://www.youtube.com/c/MarinersForex/videos'
									target='_new'>
									<i
										class='fa fa-youtube'
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								<br></br>
								<h6 style={{ color: '#1a2b48', fontSize: 12, marginTop: 20 }}>
									Download<br></br>
									<span style={{ color: '#1a2b48', fontSize: 12 }}>
										Arya Aviation App
									</span>
								</h6>
								<a
									// href='https://play.google.com/store/apps/details?id=com.mariners.marinersforex'
									target='_new'>
									<img
										src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
										width='60%'
									/>
								</a>
							</Box>
						</Grid>
					</Grid>

					<Divider style={{ background: 'grey', marginTop: 10 }} />
					<h5 style={{ textAlign: 'center', color: 'black', fontSize: 12 }}>
						Copyright ©2022, All Rights Reserved. Powered by RArya Aviation
						India Pvt. Ltd.
					</h5>
				</div>
			</Hidden>
		</>
	);
};

export default Footer;
