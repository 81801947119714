import React, { useState } from 'react';
import {
	Avatar,
	Box,
	Hidden,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BodyText from './BodyText';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from './SuccessModal';
import AA from '../../assets/images/AA.jpg';
import {
	setForgot,
	setSection,
	setSelectedTab,
	toggleChangeLogoModal,
	toggleLoginModal,
	toggleRegisterModal,
	toggleRegisterTravellerModal,
} from '../../reducers/UiReducer';
import {
	agentloginDispatch,
	travellerloginDispatch,
	agentloginSuccess,
} from '../../reducers/UserReducer';
import ErrorModal from './ErrorModal';
import Login from './Login';
const HeaderSection = ({ id }) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTheme = () => {
		let themeBtn = document.querySelector('#theme-btn');
		themeBtn.classList.toggle('fa-sun');

		if (themeBtn.classList.contains('fa-sun')) {
			document.body.classList.add('active');
		} else {
			document.body.classList.remove('active');
		}
	};

	const [user, setUser] = useState({
		username: '',
		password: '',
	});
	const { username, password } = user;
	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(agentloginDispatch(formData, history));
	};
	const onSubmit2 = async (e) => {
		e.preventDefault();
		var dat = user;
		var formData = JSON.stringify(dat);
		dispatch(travellerloginDispatch(formData, history));
	};
	const history = useHistory();
	const { tab, section } = useSelector((state) => state.ui);
	const { logo, admin } = useSelector((state) => state.home);
	const { travellerlogin, agentlogin, walletagent, wallet, agentlogoget } =
		useSelector((state) => state.user);
	return (
		<>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					paddingRight: 15,
					paddingLeft: 15,
					marginRight: 'auto',
					marginLeft: 'auto',
					background: 'rgb(0, 87, 94)',
				}}>
				<Box style={{ paddingBottom: 4.5, paddingTop: 4.5 }}>
					<i
						class='fas fa-blender-phone'
						style={{ fontSize: matches ? 12 : '', color: 'white' }}></i>
					<span style={{ color: '#065999', fontSize: 13, color: 'white' }}>
						{admin?.result[0]?.mobile_no}
					</span>
					<span
						style={{
							display: 'inline-block',
							width: '1px',
							color: 'white',
						}}></span>
					<i
						class='fa fa-fw fa-envelope'
						style={{
							fontSize: matches ? 12 : '',
							color: 'white',
							marginLeft: 10,
						}}></i>
					<span style={{ color: '#065999', fontSize: 13, color: 'white' }}>
						{admin?.result[0]?.email}
						{/* {"contact@aryaaviation.com"} */}
					</span>
				</Box>
				<Box
					display='flex'
					alignItems='center'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						dispatch(agentloginSuccess(null));
						sessionStorage.removeItem('userInfoAgent');
						dispatch(toggleChangeLogoModal(true));
						setTimeout(() => {
							dispatch(toggleChangeLogoModal(false));
							history.push('/');
						}, 1200);
					}}>
					<span style={{ color: 'white', fontSize: 14 }}>Signout</span>
				</Box>
			</Box>
			<header className='header' id='headerticket'>
				<Box onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
					<img src={AA} style={{ height: matches ? 27 : 50 }}></img>
					<span
						style={{
							color: 'rgba(139, 0, 0, 0.95)',
							fontSize: '30px',
							fontWeight: 600,
							fontFamily: 'cursive',
						}}>
						Arya Aviation
					</span>
				</Box>
				{/* </a> */}
				<Hidden only={['xs']}>
					<Box display='flex' alignItems='center'>
						<Box
							display='flex'
							alignItems='center'
							style={{ cursor: 'pointer' }}>
							<i
								class='fa-solid fa-clipboard-check'
								style={{ fontSize: 14 }}></i>
							<span
								onClick={() => history.push('/check-in')}
								style={{
									marginRight: 10,
									color: '#017959',
									fontWeight: 'bold',
									fontSize: 13,
									marginLeft: 5,
								}}>
								Web Check In
							</span>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							onClick={() => {
								dispatch(setSelectedTab('regular'));
							}}>
							<i
								class='fas fa-plane-departure'
								style={{
									color: tab === 'regular' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: tab === 'regular' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
									fontSize: 14,
								}}>
								Flight
							</span>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							onClick={() => {
								dispatch(setSelectedTab('bus'));
								// dispatch(setSection("bus"));
								// history.push("/bus-booking");
							}}>
							<i
								class='fas fa-bus'
								style={{
									color: tab === 'bus' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>
							<span
								style={{
									color: tab === 'bus' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
									fontSize: 14,
								}}>
								Buses
							</span>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'20px'}
							onClick={() => {
								dispatch(setSelectedTab('hotel'));
							}}>
							<i
								class='fas fa-building'
								style={{
									color: tab === 'hotel' ? 'orange' : '#0A95FF',
									cursor: 'pointer',
								}}></i>

							<span
								style={{
									color: tab === 'hotel' ? 'orange' : '#0A95FF',
									marginLeft: 5,
									cursor: 'pointer',
									fontSize: 14,
								}}>
								Hotels
							</span>
						</Box>

						{/* <Box
							display='flex'
							alignItems='center'
							ml={'20px'}
							mr={'20px'}
							// onClick={() => history.push('/')}
						>
							<a
								href='https://www.marinersforex.com/holiday-packages.php'
								target='_blank'>
								<i
									class='fas fa-umbrella-beach'
									style={{ color: '#0A95FF', cursor: 'pointer' }}></i>
								<span
									style={{
										color: '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Holidays
								</span>
							</a>
						</Box> */}
						{/* <Box
              display="flex"
              alignItems="center"
              ml={"20px"}
              mr={"30px"}
              onClick={() => {
                dispatch(setSection("Forex"));
                history.push("/forex");
              }}
            >
              <i
                class="fas fa-file-invoice-dollar"
                style={{
                  color: section === "Forex" ? "orange" : "#0A95FF",
                  cursor: "pointer",
                }}
              ></i>
              <span
                style={{
                  color: section === "Forex" ? "orange" : "#0A95FF",
                  marginLeft: 5,
                  cursor: "pointer",
                }}
              >
                Forex
              </span>
            </Box> */}
						<Hidden only={['xs']}>
							<Box display='flex' alignItems='center'>
								{travellerlogin && travellerlogin.status === 200 ? (
									<Box
										ml={'10px'}
										display='flex'
										alignItems='center'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push('/dashboard')}>
										<Avatar
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
											}}>
											{travellerlogin &&
												travellerlogin.result &&
												travellerlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : agentlogin && agentlogin.status === 200 ? (
									<Box style={{ alignItems: 'center' }} display='flex'>
										<Box
											style={{
												marginRight: 10,
												fontSize: 14,
												cursor: 'pointer',
												fontWeight: 600,
											}}
											onClick={() => history.push('/dashboard')}>
											{`Welcome ${
												agentlogin ? agentlogin?.result.result.agencyName : ''
											}[Agent Code : AID10000${
												agentlogin ? agentlogin?.result.result.id : ''
											}]`}
										</Box>

										<Box style={{ fontSize: 16, marginRight: 15 }}>
											₹ Available Balance:{' '}
											{walletagent ? walletagent?.result.balanceAmount : ''}
										</Box>
										<Avatar
											onClick={() => history.push('/dashboard')}
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
												marginRight: 15,
												cursor: 'pointer',
											}}>
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : (
									<>
										<Box
											onClick={() => dispatch(toggleLoginModal(true))}
											style={{
												background: 'rgb(0, 55, 114)',
												color: 'white',
												padding: '8px',
												fontWeight: 'bold',
												fontSize: '.8em',
												cursor: 'pointer',
											}}>
											Login or Create Account
										</Box>
									</>
								)}
							</Box>
						</Hidden>
					</Box>
				</Hidden>
				<Hidden only={['lg', 'xl', 'sm', 'md']}>
					<div className='icons'>
						{agentlogin && agentlogin.status === 200 ? (
							<Avatar
								onClick={() => history.push('/dashboard')}
								style={{
									color: 'white',
									background: 'rgb(239, 51, 57)',
									marginRight: 15,
									cursor: 'pointer',
									borderRadius: 15,
								}}>
								{agentlogin &&
									agentlogin.result &&
									agentlogin.result.result.name
										.match(/(\b\S)?/g)
										.join('')
										.match(/(^\S|\S$)?/g)
										.join('')
										.toUpperCase()}
							</Avatar>
						) : (
							<Box
								onClick={() => dispatch(toggleLoginModal(true))}
								style={{
									background: 'rgb(0, 55, 114)',
									color: 'white',
									// padding: '8px',
									fontWeight: 'bold',
									fontSize: '10px',
									cursor: 'pointer',
									width: '100%',
									paddingLeft: 5,
									paddingRight: 5,
								}}>
								<span>Login or Create Account</span>
							</Box>
						)}
					</div>
				</Hidden>

				<form
					onSubmit={(e) => onSubmit(e)}
					className='login-form'
					style={{ padding: 20 }}>
					<div className='inputBox'>
						<span>Username</span>
						<input
							type='email'
							name='username'
							style={{ fontSize: 14 }}
							value={username}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your name'
						/>
					</div>

					<div className='inputBox'>
						<span>Password</span>
						<input
							type='password'
							name='password'
							style={{ fontSize: 14 }}
							value={password}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your password'
						/>
					</div>

					<input
						onClick={() => {
							let loginForm = document.querySelector('.login-form');
							loginForm.classList.toggle('active');
						}}
						type='submit'
						className='btn'
						value='LOGIN'
						style={{ textAlign: 'center', fontSize: 14, borderRadius: 5 }}
					/>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<Box onClick={() => dispatch(toggleRegisterModal(true))}>
							{' '}
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 14,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								Signup
							</BodyText>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>

						<Box
							onClick={() => {
								history.push('/resetpassword');
								dispatch(setForgot('agent'));
							}}>
							<u>
								<BodyText
									textStyle={{
										// color: "#E61855",
										fontWeight: 600,
										marginTop: 10,
										fontSize: 13,
										cursor: 'pointer',
										textAlign: 'center',
									}}>
									Forgot Password ?
								</BodyText>
							</u>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>
					</Box>
				</form>
				<form
					onSubmit={(e) => onSubmit2(e)}
					className='login-form2'
					style={{ padding: 20 }}>
					<div className='inputBox2'>
						<span>Username</span>
						<input
							type='email'
							name='username'
							value={username}
							style={{ fontSize: 14 }}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your name'
						/>
					</div>

					<div className='inputBox2'>
						<span>Password</span>
						<input
							type='password'
							name='password'
							style={{ fontSize: 14 }}
							value={password}
							onChange={(e) => onInputChange(e)}
							placeholder='enter your password'
						/>
					</div>

					<input
						onClick={() => {
							let loginForm = document.querySelector('.login-form2');
							loginForm.classList.toggle('active2');
						}}
						type='submit'
						className='btn'
						value='login'
						style={{ textAlign: 'center', fontSize: 14, borderRadius: 5 }}
					/>
					<Box
						alignItems='center'
						display='flex'
						justifyContent='space-between'>
						<Box onClick={() => dispatch(toggleRegisterTravellerModal(true))}>
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 14,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								Signup
								<Divider style={{ backgroundColor: '#E61855' }} />
							</BodyText>
						</Box>
						<Box
							onClick={() => {
								history.push('/resetpassword');
								dispatch(setForgot('traveller'));
							}}>
							<BodyText
								textStyle={{
									// color: "#E61855",
									fontWeight: 600,
									marginTop: 10,
									fontSize: 13,
									cursor: 'pointer',
									textAlign: 'center',
								}}>
								{' '}
								Forgot Password ?
							</BodyText>
							<Divider style={{ backgroundColor: '#E61855' }} />
						</Box>
					</Box>
				</form>
			</header>
			<SuccessModal />
			<ErrorModal />
			<Login />
		</>
	);
};

export default HeaderSection;
