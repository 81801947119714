import React, { useEffect } from 'react';
import {
	Grid,
	Box,
	makeStyles,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
	Container,
} from '@material-ui/core';
import '../home.css';
import './Homepage2.css';
import './Agents.css';
import Footer from './Footer';
import TitleText from './TitleText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MSearchFlight from '../../form/MSearch';
import Carousel from '../../components/reusableComponents/Carousels';
import FDSearchEngine from '../../form/FDSearchEngine';
import {
	setSelectedOrigin,
	setSelectedDepart,
	setHotelToggle,
} from '../../reducers/UiReducer';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import { setSection, setSelectedTab } from '../../reducers/UiReducer';
import saudi_banner from '../../assets/images/saudi_banner.jpg';
import CarouselBottom from '../../components/reusableComponents/CarouselBottom';
import CarouselInterHoliday from '../../components/reusableComponents/CarouselInterHoliday';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFooterSub_Menu,
	fetchFooterMenu,
	fetchTravelBlog,
	fetchPolularDestination,
	fetchHotel,
	fetchDeal,
	fetchAd,
	fetchlogo,
	fetchAdmin,
	// fetchSeo,
	fetchCityDispatch,
} from '../../reducers/HomeReducer';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import images from '../../assets/images/images.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchBusCityDispatch } from '../../reducers/BusReducer';
import { fetchHotelCityDispatch } from '../../reducers/HotelReducer';
import Meta from '../../components/reusableComponents/Meta';
import SearchEngineArya from '../../form/SearchEngineArya';
import { fdSectorDispatch } from '../../reducers/FDReducer';
import SearchBus2 from '../../form/SearchBus2';
import HotelForm from '../../form/HotelForm';
import LoadingModal from '../../components/reusableComponents/LoadingModal';
import FAQ from './FAQ';
const travel = [
	{
		title: 'Happy Travelers',
		desc: 'Trusted by over 10 million+ travelers',
		icon: 'https://img.freepik.com/premium-vector/smiling-face-emoji_1319-431.jpg?w=2000',
	},
	{
		title: 'Best Price Guaranteed',
		desc: 'Get cheapest prices on all flight bookings',
		icon: 'https://cdn-icons-png.flaticon.com/512/1169/1169918.png',
	},
	{
		title: 'Safe & Secure Payments',
		desc: 'All online transactions are 100% encrypted & secure',
		icon: 'https://www.pngitem.com/pimgs/m/20-202842_safe-online-payments-with-3-d-secure-secure.png',
	},
	{
		title: '24 x 7 Customer Support',
		desc: 'We strive for 100% customer satisfaction',
		icon: 'https://static.vecteezy.com/system/resources/previews/005/747/712/original/24-hours-customer-service-icon-24-7-support-icon-sign-button-customer-service-icon-vector.jpg',
	},
];
const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingRight: 15,
		paddingLeft: 15,
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	header: {
		background: '#fff',
		boxShadow: '0px 0px 10px rgb(0 0 0 / 5%)',
	},
	back: {
		// backgroundImage: `url(${mIamge})`,

		// backgroundPosition: '1px -241px',
		zIndex: 0,
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		transition: 'background-image 300ms ease-in 200ms',
	},
	app: {
		backgroundImage: `url(${'https://pages.trip.com/images/online-adu/bg.webp'})`,
		zIndex: 0,
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		transition: 'background-image 300ms ease-in 200ms',
	},
}));
const HomePage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const dt = new Date();
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const matches2 = useMediaQuery(theme.breakpoints.down('sm'));
	const { section, tab } = useSelector((state) => state.ui);
	const { popularDesc, deal, ad, admin, seo } = useSelector(
		(state) => state.home
	);
	const { agentlogin } = useSelector((state) => state.user);
	window.onscroll = () => {
		let loginForm = document.querySelector('.login-form');
		let navbar = document.querySelector('.navbar');
		navbar.classList.remove('active');
		loginForm.classList.remove('active');
	};

	useEffect(() => {
		let form = {};
		dispatch(fetchFooterMenu(form));
		dispatch(fetchFooterSub_Menu(form));
		dispatch(fetchTravelBlog(form));
		dispatch(fetchHotelCityDispatch(form));
		dispatch(fetchBusCityDispatch(form));
		dispatch(fetchPolularDestination(form));
		// dispatch(fdSectorDispatch(form));
		dispatch(fdSectorDispatch(form));

		dispatch(fetchAd(form));
		dispatch(fetchHotel(form));
		// dispatch(fetchSeo(form));
		dispatch(fetchAdmin(form));
		dispatch(fetchlogo(form));
		dispatch(fetchDeal(form));
		if (agentlogin && agentlogin.status === 200) {
			let logodata = {
				agent_id: agentlogin && agentlogin.result.result.id,
			};
			dispatch(agentlogogetDispatch(logodata));

			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
		if (location.pathname === '/') {
			dispatch(setSection('flight'));
			history.push('/Flights-Booking');
		} else if (location.pathname === '/bus-booking') {
			dispatch(setSection('bus'));
		} else if (location.pathname === '/hotel-booking') {
			dispatch(setSection('hotel'));
		}
		let cityData = {
			keywords: 'l',
			limits: 8,
		};
		dispatch(fetchCityDispatch(cityData));
	}, []);

	return (
		<>
			{/* {section === "flight" ? (
        <Meta
          title={seo && seo.result[1].meta_title}
          description={seo && seo.result[1].meta_descr}
          keywords={seo && seo.result[1].meta_keyword}
        />
      ) : section === "bus" ? (
        <Meta
          title={seo && seo.result[3].meta_title}
          description={seo && seo.result[3].meta_descr}
          keywords={seo && seo.result[3].meta_keyword}
        />
      ) : section === "hotel" ? (
        <Meta
          title={seo && seo.result[2].meta_title}
          description={seo && seo.result[2].meta_descr}
          keywords={seo && seo.result[2].meta_keyword}
        />
      ) : section === "Forex" ? (
        <Meta
          title={seo && seo.result[5].meta_title}
          description={seo && seo.result[5].meta_descr}
          keywords={seo && seo.result[5].meta_keyword}
        />
      ) : (
        ""
      )} */}
			<HeaderSection />
			<div
				style={{
					background: 'linear-gradient(to right,rgb(0, 87, 94),#077cc1)',
					borderBottomLeftRadius: '50%',
					borderBottomRightRadius: '50%',
				}}>
				<Box style={{ textAlign: 'center', padding: 1, marginTop: 50 }}>
					<h1>
						<span
							style={{
								color: 'white',
								fontSize: matches ? 24 : 36,
								fontWeight: 'bold',
							}}>
							Book
						</span>
						{/* <span
							style={{
								color: 'white',
								marginLeft: 5,
								fontSize: matches ? 24 : 30,
							}}>
							India
						</span> */}
						<span
							style={{
								color: 'white',
								marginLeft: 5,
								fontSize: matches ? 24 : 30,
								fontWeight: 'bold',
							}}>
							Flights, Hotels & Holiday Packages
						</span>
					</h1>
				</Box>

				<Grid container>
					<Grid item xs={12} lg={4}></Grid>
					<Grid
						item
						xs={12}
						lg={4}
						style={{
							background: 'white',
							boxShadow: '1px 3px 5px #c0c0c0',
							textAlign: 'center',
							borderRadius: 25,
							padding: 10,
							position: 'relative',
							top: 15,
						}}>
						<span
							onClick={() => dispatch(setSelectedTab('regular'))}
							style={{
								color: tab === 'regular' ? 'rgb(7, 124, 193)' : '',
								fontWeight: 'bold',
								cursor: 'pointer',
							}}>
							<i
								class='fa fa-plane'
								aria-hidden='true'
								style={{ marginRight: 5 }}></i>
							Flights
						</span>
						<span
							onClick={() => dispatch(setSelectedTab('fd'))}
							style={{
								color: tab === 'fd' ? 'rgb(7, 124, 193)' : '',
								fontWeight: 'bold',
								marginLeft: 15,
								cursor: 'pointer',
							}}>
							<i
								class='fa fa-plane'
								aria-hidden='true'
								style={{ marginRight: 5 }}></i>
							Promo Fare
						</span>
						<span
							onClick={() => dispatch(setSelectedTab('bus'))}
							style={{
								color: tab === 'bus' ? 'rgb(7, 124, 193)' : '',
								fontWeight: 'bold',
								marginLeft: 15,
								cursor: 'pointer',
							}}>
							<i
								class='fa fa-bus'
								aria-hidden='true'
								style={{ marginRight: 5 }}></i>
							Buses
						</span>
						<span
							onClick={() => dispatch(setSelectedTab('hotel'))}
							style={{
								color: tab === 'hotel' ? 'rgb(7, 124, 193)' : '',
								fontWeight: 'bold',
								marginLeft: 15,
								cursor: 'pointer',
							}}>
							<i
								class='fa fa-hotel'
								aria-hidden='true'
								style={{ marginRight: 5 }}></i>
							Hotels
						</span>
					</Grid>
					<Grid item xs={12} lg={4}></Grid>
				</Grid>
				{/* <Bookingcss> */}
				{/* <Icondiv className="icondiv"/> */}
				{/* <Icondiv className="icondiv"></Icondiv> */}

				{/* </Bookingcss> */}
				<div
					style={{
						paddingLeft: matches ? 0 : 100,
						paddingRight: matches ? 0 : 100,
						marginTop: matches ? 25 : 0,
					}}>
					{/* <SearchEngineArya /> */}
					{/* <FDSearchEngine /> */}
					{/* <SearchEngineArya2/> */}

					{tab === 'regular' ? (
						<SearchEngineArya />
					) : tab === 'fd' ? (
						<FDSearchEngine />
					) : tab === 'bus' ? (
						<SearchBus2 />
					) : tab === 'hotel' ? (
						<HotelForm />
					) : (
						''
					)}
				</div>
			</div>
			<marquee>
				<div
					style={{
						marginTop: 10,
						background: 'rgb(38, 58, 74)',
						width: 'fit-content',
					}}>
					<span style={{ marginLeft: 10, color: 'white', fontWeight: 'bold' }}>
						IMPORTANT :-
					</span>
					<span style={{ marginLeft: 10, color: 'white' }}>
						CHANGE IN MALDIVIAN GOVT. GST – Increased from 12% to 16% for Travel
						effective 1st Jan’2023 onwards.
					</span>
				</div>
			</marquee>
			<div
				style={{
					paddingLeft: matches ? 22 : 100,
					paddingRight: matches ? 22 : 100,
					marginBottom: 22,
					marginTop: 40,
				}}>
				<Grid container>
					{travel.map((i, index) => {
						return (
							<Grid item xs={12} lg={3} style={{ marginTop: matches ? 20 : 0 }}>
								<Box display='flex'>
									<img src={i.icon} style={{ height: 30, borderRadius: 25 }} />
									<Box style={{ marginLeft: 5 }}>
										<span style={{ color: '#00575e', fontWeight: 'bold' }}>
											{i.title}
										</span>
										<p style={{ wordWrap: 'break-word', fontSize: 12 }}>
											{i.desc}
										</p>
									</Box>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</div>
			<div
				style={{
					paddingLeft: matches ? 0 : 100,
					paddingRight: matches ? 0 : 100,
					marginTop: 20,
				}}>
				<img src={saudi_banner} style={{ width: '100%', height: '170px' }} />
			</div>
			{/* <div style={{ position: 'relative' }}>
				<section
					onClick={() => {
						let loginForm = document.querySelector('.login-form');
						let navbar = document.querySelector('.navbar');
						navbar.classList.remove('active');
						loginForm.classList.remove('active');
					}}
					className={classes.back}
					style={{
						height: matches ? 620 : 350,
						backgroundImage: `url(${admin?.result[0]?.logo_url})`,
					}}
					// data-aos='zoom-in'
				>
					<MSearchFlight />
					<Box display='flex' onClick={() => dispatch(setHotelToggle(false))}>
						<div style={{ marginTop: 10, background: '#263a4a' }}>
							<p
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 600,
									marginLeft: 5,
								}}>
								HotDeals
							</p>
						</div>
						<marquee>
							<div
								style={{
									marginTop: 10,
									background: '#263a4a',
									width: 'maxContent',
								}}>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
							</div>
						</marquee>
					</Box>
				</section>
			</div> */}

			<Box
				style={{ padding: 20 }}
				onClick={() => dispatch(setHotelToggle(false))}>
				<h3
					style={{
						color: '#0c2f54',

						fontFamily: '"Poppins", sans-serif',
						fontSize: matches2 ? 16 : '',
					}}>
					Popular Attractions
				</h3>
				<span
					style={{
						fontFamily: '"Poppins", sans-serif',
						fontSize: matches2 ? 10 : 14,
					}}>
					Enjoy unforgettable experiences in dream hotels worldwide
				</span>
				<div style={{ marginTop: 10 }}>
					<Carousel />
				</div>
			</Box>

			<Grid container>
				<Grid item xs={12} sm={6} lg={6}>
					<img
						src={deal && deal?.result[0].image}
						style={{ width: '90%', marginLeft: 20 }}></img>
				</Grid>
				<Grid item xs={12} sm={6} lg={6}>
					<img
						src={deal && deal?.result[1].image}
						style={{
							width: '90%',
							marginLeft: matches ? 20 : '',
							marginTop: matches ? 20 : '',
						}}></img>
				</Grid>
			</Grid>
			<div
				style={{
					padding: 20,
					boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
				}}>
				<TitleText
					four
					textStyle={{
						fontFamily: 'Roboto Slab',
						color: '#00325B',
						fontSize: 36,
						textAlign: 'center',
					}}>
					Start your travel planning here
				</TitleText>
				{/* <h2
					style={{
						textAlign: 'center',
						fontWeight: 600,
						color: '#0c2f54',
						fontFamily: '"Poppins", sans-serif',
					}}>
					Start your travel planning here
				</h2> */}
				<Container style={{ marginTop: 20 }}>
					<Grid container spacing={2}>
						{popularDesc &&
							popularDesc?.result?.map((i, index) => {
								return (
									<Grid item xs={12} lg={4} alignItems='center'>
										<Box justifyContent='space-between' display='flex'>
											<h4>
												{i.destination.split(',')[0]}
												{/* {i.destination.split(",")[1].split("-")[0]} */}
											</h4>

											<ExpandMoreIcon />
										</Box>
										<Box>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: index === 0 ? 0 : 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.first
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.first.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.first.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.first.split(',')[0],
														country_name: i.first.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.first.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.second
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.second.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.second.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.second.split(',')[0],
														country_name: i.second.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.second.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.third
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.third.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.third.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.third.split(',')[0],
														country_name: i.third.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.third.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.fourth
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.fourth.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.fourth.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.fourth.split(',')[0],
														country_name: i.fourth.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.fourth.split(',')[0]}
											</span>
										</Box>
										<Divider style={{ marginTop: 10 }} />
									</Grid>
								);
							})}
					</Grid>
				</Container>
			</div>
			<a
				target='_blank'
				href='https://wa.me/message/AFTRTRUNTMRNC1'
				style={{
					position: 'fixed',
					width: '45px',
					height: '45px',
					right: '0px',
					backgroundColor: '#25d366',
					color: '#fff',
					borderTopLeftRadius: '5px',
					borderBottomLeftRadius: '5px',
					textAlign: 'center',
					fontSize: '30px',
					zIndex: 100,
					padding: '5px',
					top: '180px',
					fontFamily: 'Poppins',
				}}>
				<img
					src='https://sarvartravels.com/images/whatsapp.png'
					style={{ width: '100%', marginBottom: 14 }}
				/>
			</a>
			<div
				onClick={() => (window.location = 'tel:887-777-3388')}
				style={{
					position: 'fixed',
					cursor: 'pointer',
					width: '45px',
					height: '45px',
					right: '0px',
					backgroundColor: 'white',
					color: '#fff',
					borderTopLeftRadius: '5px',
					borderBottomLeftRadius: '5px',
					textAlign: 'center',
					fontSize: '30px',
					zIndex: 100,
					padding: '5px',
					top: '226px',
					fontFamily: 'Poppins',
					border: '1px solid grey',
				}}>
				<img
					src='https://static.vecteezy.com/system/resources/previews/004/956/037/non_2x/phone-call-icon-vector.jpg'
					style={{ width: '100%', marginBottom: 14 }}
				/>
			</div>
			{/* <Container>
				<a href={ad?.result[0]?.link} target='_blank'>
					<img src={ad?.result[0]?.image} style={{ width: '100%' }}></img>
				</a>
				<h2
					style={{
						fontFamily: '"Poppins", sans-serif',
						color: '#0c2f54',
						fontSize: matches2 ? 16 : '',
					}}>
					International Holidays
				</h2>
				<p style={{ marginBottom: 12 }}>
					Explore a variety of holiday tour packages and create lasting memories
				</p>
				<CarouselInterHoliday />
			</Container> */}
			<Container>
				<a href={ad?.result[1]?.link} target='_blank'>
					<img src={ad?.result[1]?.image} style={{ width: '100%' }}></img>
				</a>
			</Container>
			<Grid
				container
				style={{ padding: 20, alignItems: 'center', textAlign: 'center' }}
				className={classes.app}>
				<Grid item xs={12} lg={4}>
					<img
						src='https://pages.trip.com/images/app-download/screenshot/en-xx-main.jpg'
						style={{
							height: '605px',
							width: '248px',
							backgroundRepeat: 'no-repeat',
							backgroundSize: '100%',
							backgroundColor: '#e7ebf0',
							borderRadius: '48px',
							position: 'relative',
							boxShadow:
								'-8px 8px 24px rgb(69 88 115 / 32%), inset 0 0 8px rgb(133 146 166 / 50%)',
							border: '14px solid #e7ebf0',
							boxSizing: 'content-box',
						}}
					/>
				</Grid>
				<Grid item xs={12} lg={8}>
					<h1 style={{ fontWeight: 'bold', fontSize: 50, color: 'white' }}>
						Your all-in-one travel app.
					</h1>
					<h4 style={{ color: 'white' }}>
						Book flights, hotels, bus anywhere in the world in just seconds. Get
						real-time flight updates, travel info, exclusive deals, and 30% more
						Trip only on the app!
					</h4>
					<img
						style={{ height: 170 }}
						src={
							'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
						}
					/>
				</Grid>
				{/* <Grid item xs={2}></Grid> */}
			</Grid>
			<FAQ />
			<Footer />
			<LoadingModal />
		</>
	);
};

export default HomePage;
