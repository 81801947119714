import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  Hidden,
  useMediaQuery,
  useTheme,
  Divider,
} from "@material-ui/core";
import AA from "../../assets/images/AA.jpg";
// import saudi_banner from "../../assets/images/saudi_banner.jpg";
// import { Icondiv } from "../../components/mmtrip/Icondiv";
import Footer from "../Desktop/Footer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  fetchCashback,
  fetchcoupan,
  fetchDepart,
  fetchFooterMenu,
  fetchFooterSub_Menu,
  fetchHotel,
  fetchlogo,
  fetchOrigin,
  searchSuccess,
  searchSuccessEtrav,
} from "../../reducers/HomeReducer";
import CarouselInterHoliday from "../../components/reusableComponents/CarouselInterHoliday";
// import SearchEngineArya from "../../form/SearchEngineArya";
import { setSelectedDepart, setSelectedOrigin } from "../../reducers/UiReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  agentloginSuccess,
  agentlogogetDispatch,
  walletBalanceAgentDispatch,
} from "../../reducers/UserReducer";
// import FDSearchEngine from "../../form/FDSearchEngine";
// import { fdSectorDispatch } from "../../reducers/FDReducer";
import LoaderModal from "../../components/reusableComponents/LoaderModal";
// import HolidaySectionModal from "../../components/reusableComponents/HolidaySectionModal";
import HeaderSection from "../../components/reusableComponents/HeaderSection";
const travel = [
  {
    title: "Happy Travelers",
    desc: "Trusted by over 10 million+ travelers",
    icon: "https://img.freepik.com/premium-vector/smiling-face-emoji_1319-431.jpg?w=2000",
  },
  {
    title: "Best Price Guaranteed",
    desc: "Get cheapest prices on all flight bookings",
    icon: "https://cdn-icons-png.flaticon.com/512/1169/1169918.png",
  },
  {
    title: "Safe & Secure Payments",
    desc: "All online transactions are 100% encrypted & secure",
    icon: "https://www.pngitem.com/pimgs/m/20-202842_safe-online-payments-with-3-d-secure-secure.png",
  },
  {
    title: "24 x 7 Customer Support",
    desc: "We strive for 100% customer satisfaction",
    icon: "https://static.vecteezy.com/system/resources/previews/005/747/712/original/24-hours-customer-service-icon-24-7-support-icon-sign-button-customer-service-icon-vector.jpg",
  },
];

const HomeArya = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dt = new Date();
  const { agentlogin, walletagent } = useSelector((state) => state.user);
  const { tab } = useSelector((state) => state.ui);
  const { origin } = useSelector((state) => state.home);
  const { loadingfd } = useSelector((state) => state.promo);
  const history = useHistory();
  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    // let form = {};
    // dispatch(fetchCashback(form));
    // dispatch(fetchFooterMenu(form));
    // dispatch(fetchFooterSub_Menu(form));
    // // dispatch(fdSectorDispatch(form));
    // dispatch(fetchOrigin(form));
    // dispatch(fetchDepart(form));
    // dispatch(fetchlogo(form));
    // dispatch(searchSuccess(null));
    // dispatch(searchSuccessEtrav(null));
    // dispatch(fetchHotel(form));
    // dispatch(fetchcoupan(form));
    // if (agentlogin && agentlogin.status === 200) {
    //   let logodata = {
    //     agent_id: agentlogin && agentlogin.result.result.id,
    //   };
    //   dispatch(agentlogogetDispatch(logodata));
    //   dispatch(
    //     walletBalanceAgentDispatch(
    //       agentlogin && agentlogin.result.result.email,
    //       agentlogin && agentlogin.result.result.id
    //     )
    //   );
    // }
  }, []);
  return (
    <>
      <div style={{ background: "#f8f8f8", paddingBottom: 1 }}>
        {/* <Hidden only={["xs"]}>
          <div style={{ background: "rgb(0, 87, 94)", padding: "6px 8px" }}>
            <Container>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box>
                      <span
                        style={{
                          marginLeft: 10,
                          color: "white",
                          fontSize: 14,
                        }}
                      >
                        Phone:+91 8877773388
                      </span>
                    </Box>

                    <Box>
                      <span
                        style={{
                          marginLeft: 10,
                          color: "white",
                          fontSize: 14,
                          marginRight:10
                        }}
                      >
                        Email: contact@aryaaviation.com
                      </span>
                    </Box>
                    <Box
              style={{ marginRight: 35, cursor: "pointer", fontWeight: "bold",color:'white',fontSize:12 }}
              onClick={() => {
                dispatch(agentloginSuccess(null));
                sessionStorage.removeItem("userInfoAgent");
                history.push("/");
              }}
            >
              Signout
            </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Hidden> */}
        {/* <HeaderSection/> */}
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingLeft: matches ? 18 : 100,
            paddingRight: matches ? 18 : 100,
            marginTop: 5,
            background: "white",
            cursor: "pointer",
          }}
        >
          <Box alignItems={"center"}>
            <img src={AA} style={{ height: 50 }}></img>
            <span
              style={{
                color: "#8b0000f2",
                fontSize: "30px",
                fontWeight: 600,
                fontFamily: "cursive",
              }}
            >
              Arya Aviation
            </span>
          </Box>
          <Box style={{ alignItems: "center" }} display="flex">
            <Box
              style={{
                marginRight: 10,
                fontSize: 19,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => history.push("/dashboard")}
            >
              {agentlogin ? agentlogin?.result.result.agencyName : ""}
            </Box>
            <Box style={{ fontSize: 18 }}>
              Available Balance: ₹{" "}
              {walletagent ? walletagent?.result.balanceAmount : ""}
            </Box>
          </Box>
        </Box> */}
        <div
          style={{
            background: "linear-gradient(to right,rgb(0, 87, 94),#077cc1)",
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
          }}
        >
          <Box style={{ textAlign: "center", padding: 1 }}>
            <h1>
              <span style={{ color: "white", fontSize: matches ? 24 : 36 }}>
                Book
              </span>
              <span
                style={{
                  color: "white",
                  marginLeft: 5,
                  fontSize: matches ? 24 : 36,
                }}
              >
                India
              </span>
              <span
                style={{
                  color: "white",
                  marginLeft: 5,
                  fontSize: matches ? 24 : 36,
                }}
              >
                Flights, Hotels & Holiday Packages
              </span>
            </h1>
          </Box>
          {/* <Bookingcss> */}
          {/* <Icondiv className="icondiv"></Icondiv> */}

          {/* </Bookingcss> */}
          <div
            style={{
              paddingLeft: matches ? 0 : 100,
              paddingRight: matches ? 0 : 100,
            }}
          >
            {/* {tab === "regular" ? <SearchEngineArya /> : <FDSearchEngine />} */}
          </div>
          {/* </Container> */}
        </div>
        <div
          style={{
            paddingLeft: matches ? 22 : 100,
            paddingRight: matches ? 22 : 100,
            marginBottom: 22,
          }}
        >
          <Grid container>
            {travel.map((i, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  lg={3}
                  style={{ marginTop: matches ? 20 : 0 }}
                >
                  <Box display="flex">
                    <img
                      src={i.icon}
                      style={{ height: 30, borderRadius: 25 }}
                    />
                    <Box style={{ marginLeft: 5 }}>
                      <span style={{ color: "#00575e", fontWeight: "bold" }}>
                        {i.title}
                      </span>
                      <p style={{ wordWrap: "break-word", fontSize: 12 }}>
                        {i.desc}
                      </p>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <div
        style={{
          paddingLeft: matches ? 0 : 100,
          paddingRight: matches ? 0 : 100,
          marginTop: 20,
        }}
      >
        {/* <img src={saudi_banner} style={{ width: "100%" }} /> */}
      </div>
      <div
        style={{
          padding: 20,
          boxShadow: "0px 0px 50px -35px rgb(0 0 0 / 40%)",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontWeight: 600,
            color: "#0c2f54",
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          Start your travel planning here
        </h1>
        <Container style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            {origin &&
              origin?.result?.map((i, index) => {
                return (
                  <Grid item xs={12} lg={4} alignItems="center">
                    <Box justifyContent="space-between" display="flex">
                      <h3>
                        {i.destination.split(",")[0]}
                        {/* {i.destination.split(",")[1].split("-")[0]} */}
                      </h3>

                      <ExpandMoreIcon />
                    </Box>
                    <Box>
                      <span
                        // onMouseEnter={()=>setHover(true)}
                        // onMouseLeave={()=>setHover(false)}
                        style={{
                          cursor: "pointer",
                          color: "#065999",
                          marginLeft: index === 0 ? 0 : 5,
                        }}
                        onClick={() => {
                          let formData = {
                            AdultCount: 1,
                            ChildCount: 0,
                            InfantCount: 0,
                            DirectFlight: false,
                            OneStopFlight: false,
                            JourneyType: 1,
                            PreferredAirlines: null,
                            Segments: [
                              {
                                Origin: i.destination
                                  .split(",")[1]
                                  .split("-")[0],
                                Destination: i.first
                                  .split(",")[1]
                                  .split("-")[0],
                                FlightCabinClass: 1,
                                PreferredDepartureTime: convert(
                                  dt.setDate(dt.getDate() + 7)
                                ),
                              },
                            ],
                            Sources: "WEB",
                            agent_id: agentlogin
                              ? agentlogin.status === 200
                                ? agentlogin?.result.result.id
                                : 0
                              : 0,
                            // agent_id: agentlogin&&agentlogin.status===200 ? agentlogin && agentlogin.result.result.id : 0,
                          };
                          history.push({
                            pathname: `/oneway/${
                              i.destination.split(",")[1].split("-")[0]
                            }/${i.first.split(",")[1].split("-")[0]}/${convert(
                              dt.setDate(dt.getDate() + 7)
                            )}`,
                            state: {
                              body: formData,
                              OriginO: convert(dt.setDate(dt.getDate() + 7)),
                            },
                          });
                          let originData = {
                            airport_code: i.destination
                              .split(",")[1]
                              .split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.destination.split(",")[0],
                            country_name: i.destination
                              .split(",")[1]
                              .split("-")[1],
                            country_code: "IN",
                          };
                          let departData = {
                            airport_code: i.first.split(",")[1].split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.first.split(",")[0],
                            country_name: i.first.split(",")[1].split("-")[1],
                            country_code: "IN",
                          };
                          dispatch(setSelectedOrigin(originData));
                          dispatch(setSelectedDepart(departData));
                        }}
                      >
                        {i.first.split(",")[0]}
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#065999",
                          marginLeft: 5,
                        }}
                        onClick={() => {
                          let formData = {
                            AdultCount: 1,
                            ChildCount: 0,
                            InfantCount: 0,
                            DirectFlight: false,
                            OneStopFlight: false,
                            JourneyType: 1,
                            PreferredAirlines: null,
                            Segments: [
                              {
                                Origin: i.destination
                                  .split(",")[1]
                                  .split("-")[0],
                                Destination: i.second
                                  .split(",")[1]
                                  .split("-")[0],
                                FlightCabinClass: 1,
                                PreferredDepartureTime: convert(
                                  dt.setDate(dt.getDate() + 7)
                                ),
                              },
                            ],
                            Sources: "WEB",
                            agent_id: agentlogin
                              ? agentlogin.status === 200
                                ? agentlogin?.result.result.id
                                : 0
                              : 0,
                            // agent_id: agentlogin&&agentlogin.status===200 ? agentlogin && agentlogin.result.result.id : 0,
                          };
                          history.push({
                            pathname: `/oneway/${
                              i.destination.split(",")[1].split("-")[0]
                            }/${i.second.split(",")[1].split("-")[0]}/${convert(
                              dt.setDate(dt.getDate() + 7)
                            )}`,
                            state: {
                              body: formData,
                              OriginO: convert(dt.setDate(dt.getDate() + 7)),
                            },
                          });
                          let originData = {
                            airport_code: i.destination
                              .split(",")[1]
                              .split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.destination.split(",")[0],
                            country_name: i.destination
                              .split(",")[1]
                              .split("-")[1],
                            country_code: "IN",
                          };
                          let departData = {
                            airport_code: i.second.split(",")[1].split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.second.split(",")[0],
                            country_name: i.second.split(",")[1].split("-")[1],
                            country_code: "IN",
                          };
                          dispatch(setSelectedOrigin(originData));
                          dispatch(setSelectedDepart(departData));
                        }}
                      >
                        {i.second.split(",")[0]}
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#065999",
                          marginLeft: 5,
                        }}
                        onClick={() => {
                          let formData = {
                            AdultCount: 1,
                            ChildCount: 0,
                            InfantCount: 0,
                            DirectFlight: false,
                            OneStopFlight: false,
                            JourneyType: 1,
                            PreferredAirlines: null,
                            Segments: [
                              {
                                Origin: i.destination
                                  .split(",")[1]
                                  .split("-")[0],
                                Destination: i.third
                                  .split(",")[1]
                                  .split("-")[0],
                                FlightCabinClass: 1,
                                PreferredDepartureTime: convert(
                                  dt.setDate(dt.getDate() + 7)
                                ),
                              },
                            ],
                            Sources: "WEB",
                            agent_id: agentlogin
                              ? agentlogin.status === 200
                                ? agentlogin?.result.result.id
                                : 0
                              : 0,
                            // agent_id: agentlogin&&agentlogin.status===200 ? agentlogin && agentlogin.result.result.id : 0,
                          };
                          history.push({
                            pathname: `/oneway/${
                              i.destination.split(",")[1].split("-")[0]
                            }/${i.third.split(",")[1].split("-")[0]}/${convert(
                              dt.setDate(dt.getDate() + 7)
                            )}`,
                            state: {
                              body: formData,
                              OriginO: convert(dt.setDate(dt.getDate() + 7)),
                            },
                          });
                          let originData = {
                            airport_code: i.destination
                              .split(",")[1]
                              .split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.destination.split(",")[0],
                            country_name: i.destination
                              .split(",")[1]
                              .split("-")[1],
                            country_code: "IN",
                          };
                          let departData = {
                            airport_code: i.third.split(",")[1].split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.third.split(",")[0],
                            country_name: i.third.split(",")[1].split("-")[1],
                            country_code: "IN",
                          };
                          dispatch(setSelectedOrigin(originData));
                          dispatch(setSelectedDepart(departData));
                        }}
                      >
                        {i.third.split(",")[0]}
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#065999",
                          marginLeft: 5,
                        }}
                        onClick={() => {
                          let formData = {
                            AdultCount: 1,
                            ChildCount: 0,
                            InfantCount: 0,
                            DirectFlight: false,
                            OneStopFlight: false,
                            JourneyType: 1,
                            PreferredAirlines: null,
                            Segments: [
                              {
                                Origin: i.destination
                                  .split(",")[1]
                                  .split("-")[0],
                                Destination: i.fourth
                                  .split(",")[1]
                                  .split("-")[0],
                                FlightCabinClass: 1,
                                PreferredDepartureTime: convert(
                                  dt.setDate(dt.getDate() + 7)
                                ),
                              },
                            ],
                            Sources: "WEB",
                            agent_id: agentlogin
                              ? agentlogin.status === 200
                                ? agentlogin?.result.result.id
                                : 0
                              : 0,
                            // agent_id: agentlogin&&agentlogin.status===200 ? agentlogin && agentlogin.result.result.id : 0,
                          };
                          history.push({
                            pathname: `/oneway/${
                              i.destination.split(",")[1].split("-")[0]
                            }/${i.fourth.split(",")[1].split("-")[0]}/${convert(
                              dt.setDate(dt.getDate() + 7)
                            )}`,
                            state: {
                              body: formData,
                              OriginO: convert(dt.setDate(dt.getDate() + 7)),
                            },
                          });
                          let originData = {
                            airport_code: i.destination
                              .split(",")[1]
                              .split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.destination.split(",")[0],
                            country_name: i.destination
                              .split(",")[1]
                              .split("-")[1],
                            country_code: "IN",
                          };
                          let departData = {
                            airport_code: i.fourth.split(",")[1].split("-")[0],
                            airport_name: "Chhatrapati Shivaji Intl.",
                            city_name: i.fourth.split(",")[0],
                            country_name: i.fourth.split(",")[1].split("-")[1],
                            country_code: "IN",
                          };
                          dispatch(setSelectedOrigin(originData));
                          dispatch(setSelectedDepart(departData));
                        }}
                      >
                        {i.fourth.split(",")[0]}
                      </span>
                    </Box>
                    <Divider style={{ marginTop: 10 }} />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </div>
      <a target='_blank'
        href="https://wa.me/message/AFTRTRUNTMRNC1"
        style={{
          position: "fixed",
          width: "45px",
          height: "45px",
          right: "0px",
          backgroundColor: "#25d366",
          color: "#fff",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          textAlign: "center",
          fontSize: "30px",
          zIndex: 100,
          padding: "5px",
          top: "180px",
          fontFamily: "Poppins",
        }}
      >
        <img
          src="https://sarvartravels.com/images/whatsapp.png"
          style={{ width: "100%", marginBottom: 14 }}
        />
      </a>
      <div
      onClick={() => (window.location = 'tel:887-777-3388')}
        style={{
          position: "fixed",
          cursor:'pointer',
          width: "45px",
          height: "45px",
          right: "0px",
          backgroundColor: "white",
          color: "#fff",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          textAlign: "center",
          fontSize: "30px",
          zIndex: 100,
          padding: "5px",
          top: "226px",
          fontFamily: "Poppins",
          border:'1px solid grey'
        }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/004/956/037/non_2x/phone-call-icon-vector.jpg"
          style={{ width: "100%", marginBottom: 14 }}
        />
      </div>
      <Container>
        <h2 style={{ fontFamily: '"Poppins", sans-serif', color: "#0c2f54" }}>
          Affordable Package
        </h2>
        <p style={{ fontFamily: "cursive", padding: 10 }}>
          Explore a variety of holiday tour packages and create lasting memories
        </p>
        <CarouselInterHoliday />
      </Container>
      {loadingfd ? <LoaderModal /> : ""}
      {/* <Footer /> */}
      {/* <HolidaySectionModal/> */}
    </>
  );
};

export default HomeArya;
