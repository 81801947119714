import React from 'react';
import TitleText from './TitleText';
import BodyText from './BodyText';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import Background from '../../../assets/images/textBackground.svg';

const useStyles = makeStyles((theme) => ({
	loginButton: {
		backgroundColor: 'white',
		color: '#E61855',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		paddingLeft: 30,
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 30,
		// marginBottom: 20,
		'&:hover': {
			backgroundColor: 'white',
		},
	},
	registerButton: {
		backgroundColor: '#E61855',
		color: 'white',
		marginLeft: 15,
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		paddingLeft: 30,
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 30,
		// padding: 12,
		// marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	earlyExcess: {
		backgroundColor: '#E61855',
		color: 'white',
		marginLeft: 15,
		borderColor: '#E61855',
		borderRadius: 15,
		// width: '20%',
		paddingLeft: 50,
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 50,
		// padding: 12,
		// marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	lpImgOne: {
		zIndex: 2,
		width: '100%',
		position: 'relative',
	},
	lpImgTwo: {
		width: '100%',
		position: 'absolute',
		left: '0',
		top: '0%',
	},
	sectionThreecardImg: {
		width: '100%',
	},
	sectionThreecard: {
		backgroundColor: '#fff',
		width: '100%',
		padding: '8%',
		borderRadius: '20px',
		boxShadow: '0px 5px 40px rgba(3, 98, 175, 0.1)',
		marginBottom: '5vh',
	},
	LPCon: {
		width: '90%',
	},
	paper: {
		width: '100%',
		height: '100px',
		backgroundColor: '#E6F0F7',
		opacity: ' 0.87',
		borderRadius: '10px',
		boxShadow: 'none',
		margin: '3vh 0',
	},
	LPSectionSix: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#E6F0F7',
		overflow: 'hidden',
		color: '#00325B',
		paddingBottom: '6vh',
	},

	dataSec6ConTop: {
		marginTop: '1vh',
		display: 'flex',
		width: '300%',
	},
	dataSec6ConTopBtn: {
		outline: 'none',
		border: 'none',
		background: '#E6F0F7',
		padding: '1vh 1vw',
		opacity: ' 0.3',
		margin: '0 20px',
	},
	dataSec6ConBottom: {
		position: 'relative',
		transition: 'all 0.5s ease',
		display: 'flex',
		width: '400vw',
	},
	dataSec6ConBottomLB: {
		position: 'fixed',
		left: 0,
		top: 0,
		width: '70px',
		height: '50px',
		backgroundColor: '#000',
	},
	dataSec6bCard: {
		display: 'flex',
		flexDirection: 'column',
		width: '100vw',
		marginTop: '1.5vh',
		padding: '0 10vw',
		position: 'relative',
	},

	accordionSummary: {
		flexDirection: 'row-reverse',
		width: '100%',
		padding: 0,
	},
}));
const FAQ = () => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<>
			<Grid container style={{ marginTop: 40, marginBottom: 40 }}>
				<Grid item xs={1}></Grid>
				<Grid item xs={11}>
					<Box className={classes.LPSectionEight + ' LPSectionEight'}>
						<Box className={classes.LPCon}>
							<BodyText
								one
								textStyle={{
									fontSize: '12px',
									color: '#E61855',
									fontWeight: 700,
								}}>
								FAQs
							</BodyText>

							<Box display='flex' mb='10px'>
								<TitleText
									four
									textStyle={{
										fontFamily: 'Roboto Slab',
										color: '#00325B',
										fontSize: 36,
									}}>
									Have any
								</TitleText>
								<Box display='flex' ml={0.5} alignItems='center'>
									<TitleText
										four
										textStyle={{
											fontFamily: 'Roboto Slab',
											color: '#E61855',
											marginLeft: '8px',
											fontSize: 36,
										}}>
										questions ?
									</TitleText>
									{/* <img
										src={Background}
										alt='Login'
										style={{ position: 'absolute' }}
									/> */}
								</Box>
							</Box>
							<Box className='accordianDiv' style={{ marginTop: 60 }}>
								<Accordion
									expanded={expanded === 'panel1'}
									onChange={handleChange('panel1')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											What is the best time to book flights and hotels?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											Book your flight tickets six to eight weeks in advance to
											score the best price. Hotel rates are typically lowest
											when you book about four weeks prior to your travel date.
										</BodyText>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded === 'panel2'}
									onChange={handleChange('panel2')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											What are the available payment options?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											You can pay for your flight ticket and hotel booking using
											any debit or credit card. Other payment options such as
											Amazon Pay, MobiKwik, OlaMoney and more are also
											available.
										</BodyText>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded === 'panel3'}
									onChange={handleChange('panel3')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											Where do I find flight and hotel offers?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											You can find the latest flight and hotel deals on our
											offer page.
										</BodyText>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded === 'panel4'}
									onChange={handleChange('panel4')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											How do I connect with RArya Aviation customer support?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											You can write to us at contact@aryaaviation.com, or call
											9798779484. You can also find information on our support
											page.
										</BodyText>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded === 'panel5'}
									onChange={handleChange('panel5')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											What is Lowest Airfare Guarantee?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											RAryaAviation guarantees you the lowest airfare for
											domestic flight bookings. If you can find a better deal
											anywhere else, we will pay you triple the airfare
											difference. Know more.
										</BodyText>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={expanded === 'panel6'}
									onChange={handleChange('panel6')}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ArrowRightIcon />}
										aria-controls='panel1a-content'
										id='panel1a-header'>
										<p className='accordionHead'>
											Where can I download the RAryaAviation app?
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<BodyText
											one
											textStyle={{
												textAlign: 'justify',
												fontSize: '14px',
												fontWeight: 500,
											}}>
											RAryaAviation app is available for free on Google Play
											Store (Android users).
										</BodyText>
									</AccordionDetails>
								</Accordion>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default FAQ;
