import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	Box,
	withStyles,
	Checkbox,
	Grid,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	agentedituserDispatch,
	travelleredituserDispatch,
} from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import { toggleOnlineModal } from '../../reducers/UiReducer';
import OnlineRechargeModal from './OnlineRechargeModal';
import { CleaningServices } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['Jharkhand', 'Kolkata'];

const OnlineRecharge = () => {
	const classes = useStyles();
	const [tabvalue, setValue] = React.useState(0);
	const [amount, setAmount] = useState(0);
	const date = new Date();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const handleAmount = (e) => {
		setAmount(e.target.value);
		console.log('first', e.target.value);
	};
	console.log('Amount', amount);
	const dispatch = useDispatch();
	const history = useHistory();
	const { travellerlogin, agentlogin } = useSelector((state) => state.user);
	console.log('agentlogin', agentlogin);
	const [currentStep, SetCurrentStep] = useState('Step 1');

	const handleFormSubmit = (data, actions) => {
		if (
			agentlogin &&
			agentlogin.result &&
			agentlogin.result.result &&
			agentlogin.result.result.role === 'Agent'
		) {
			let formData2 = {
				userId:
					agentlogin &&
					agentlogin.result &&
					agentlogin.result.result &&
					agentlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
				agencyName: data.agencyName,
			};
			dispatch(agentedituserDispatch(formData2, history));
		} else {
			let formData = {
				userId:
					travellerlogin &&
					travellerlogin.result &&
					travellerlogin.result.result &&
					travellerlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
			};
			dispatch(travelleredituserDispatch(formData, history));
		}
	};
	const getActualFormat = (given: number) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	return (
		<>
			<Grid container style={{ padding: 25 }} spacing={2}>
				<Grid item xs={4}>
					<label for='txnid' style={{ textAlign: 'initial', width: '100%' }}>
						Order ID
					</label>
					<input
						name='mrctTxtID'
						id='txnid'
						value={`MFD${invoice}`}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				<Grid item xs={4}>
					<label for='txnid' style={{ textAlign: 'initial', width: '100%' }}>
						Agent ID
					</label>
					<input
						name='aID'
						id='aid'
						value={agentlogin?.result?.result?.id}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				<Grid item xs={4}>
					<label
						for='firstname'
						style={{ textAlign: 'initial', width: '100%' }}>
						Agent Name
					</label>
					<input
						name='firstname'
						id='firstname'
						value={agentlogin.result.result.name}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				<Grid item xs={4}>
					<label for='phone' style={{ textAlign: 'initial', width: '100%' }}>
						Phone
					</label>
					<input
						name='phone'
						id='phone'
						value={agentlogin.result.result.phone}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				<Grid item xs={4}>
					<label for='email' style={{ textAlign: 'initial', width: '100%' }}>
						Email
					</label>
					<input
						name='email'
						id='email'
						value={agentlogin.result.result.email}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				{/* <input
					hidden
					name='returnURL'
					id='surl'
					value='https://agents.musafirbazar.com/dashboard'
				/>
				<input
					hidden
					name='failedURL'
					id='furl'
					value='https://agents.musafirbazar.com/dashboard'
					style={{ border: '1px solid', padding: 10, width: '100%' }}
				/>
				<input
					name='productinfo'
					id='productinfo'
					value='productinfo'
					hidden
					// value={location.state.orderID}
					style={{ border: '1px solid', padding: 10, width: '100%' }}
				/> */}
				<Grid item xs={4}>
					<label for='amount' style={{ textAlign: 'initial', width: '100%' }}>
						Amount
					</label>
					<input
						name='amount'
						id='amount'
						type='number'
						value={`${amount}`}
						onChange={handleAmount}
						// value={location.state.orderID}
						style={{ border: '1px solid', padding: 10, width: '100%' }}
					/>
				</Grid>
				<Grid item xs={4} style={{ marginTop: 50 }}>
					<div>
						<button
							onClick={() => dispatch(toggleOnlineModal(true))}
							className={classes.createButton}
							style={{ cursor: 'pointer' }}>
							Make Payment
						</button>
					</div>
				</Grid>
			</Grid>
			<form
			// action="https://www.newinv.musafirbazar.com/pg/easebuzz/request.php"
			// method="POST"
			>
				{/* <Grid container alignItems='center' style={{padding:10}} spacing={2}>
					<Grid item xs={4} style={{ textAlign: 'center' }}>
						<label for='txnid' style={{textAlign:'initial',width:'100%'}}>OrderID</label>
                        <input
							name='mrctTxtID'
							id='txnid'
                            value='MFD8596'
							// value={location.state.orderID}
							style={{border:'1px solid',padding:10,width:'100%'}}
						/>
					</Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
						<label for='txnid' style={{textAlign:'initial',width:'100%'}}>OrderID</label>
                        <input
							name='mrctTxtID'
							id='txnid'
                            value='MFD8596'
							// value={location.state.orderID}
							style={{border:'1px solid',padding:10,width:'100%'}}
						/>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
					
					</Grid>
				</Grid> */}
			</form>
			<SuccessModal />
			<OnlineRechargeModal
				orderId={`MFD${invoice}`}
				agentId={agentlogin?.result?.result?.id}
				agentName={agentlogin?.result?.result?.name}
				phone={agentlogin?.result?.result?.phone}
				email={agentlogin?.result?.result?.email}
				amount={amount}
			/>
			<ErrorModal />
		</>
	);
};

export default OnlineRecharge;
