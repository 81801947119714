import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	Tabs,
	Tab,
	withStyles,
	Checkbox,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import BodyText from '../../components/reusableComponents/BodyText';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import {
	agentloginDispatch,
	agentSignupDispatch,
	travellerloginDispatch,
	travellerSignupDispatch,
} from '../../reducers/UserReducer';
import { toggleLoginModal } from '../../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		// marginTop: 10,
		borderRadius: 25,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 25,
			marginBottom: 16,
			// marginTop: 10,
		},
		// '& .MuiInputLabel-root': {
		// 	dispaly: 'none',
		// },
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#39c9bb',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#39c9bb',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
		fontSize: 12,

	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12
		},
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 70,
				// fontSize: 16,
			},
		},
	},
	chec: {
		'& .MuiTypography-root': {
			// fontSize: 12,
		},
	},
}));
const LoginForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [tabvalue, setValue] = React.useState(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const [check, setCheck] = useState('agent');
	const handleTab = (event) => {
		setCheck(event);
	};
	const handleFormSubmit = (data, actions) => {
		if(check==='agent'){
			if (data.userFirstName) {
				console.log(data);
				let formData = {
					userTitle: 'Mr',
					userFirstName: data.userFirstName,
					userLastName: data.userLastName,
					userPhone: data.phone2,
					userEmail: data.email2,
					password: data.password2,
					confirmpassword: data.confirm_password2,
				};
				console.log(formData);
				dispatch(agentSignupDispatch(formData, history));
				dispatch(toggleLoginModal(false))
			} else {
				let formData = {
					username: data.email,
					password: data.password, //converting password to base64
				};
				console.log(formData);
				dispatch(agentloginDispatch(formData, history));
				dispatch(toggleLoginModal(false))
			}
	
		}else{
			if (data.userFirstName) {
				console.log(data);
				let formData = {
					userTitle: 'Mr',
					userFirstName: data.userFirstName,
					userLastName: data.userLastName,
					userPhone: data.phone2,
					userEmail: data.email2,
					password: data.password2,
					confirmpassword: data.confirm_password2,
				};
				console.log(formData);
				dispatch(travellerSignupDispatch(formData,history))
				dispatch(toggleLoginModal(false))
			} else {
				let formData = {
					username: data.email,
					password: data.password, //converting password to base64
				};
				console.log(formData);
				dispatch(travellerloginDispatch(formData,history))
				dispatch(toggleLoginModal(false))
			}
		}
		
		// dispatch(loginDispatch(formData, actions, history, checked));
	};
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validate={(values) => {
				const errors = {};
				if (tabvalue === 0) {
					if (!values.email) {
						errors.email = 'Required';
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
					) {
						errors.email = 'Invalid email address';
					}
					if (!values.password) {
						errors.password = 'Required';
					}
				}
				if (tabvalue === 1) {
					if (!values.email2) {
						errors.email2 = 'Required';
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email2)
					) {
						errors.email2 = 'Invalid email address';
					}
					if (!values.password2) {
						errors.password2 = 'Required';
					}
					if (!values.confirm_password2) {
						errors.confirm_password2 = 'Required';
					} else if (values.confirm_password2 === values.password2) {
						errors.confirm_password2 = 'Required';
					}
					if (!values.phone2) {
						errors.phone2 = 'Required';
					}
					if (!values.userFirstName) {
						errors.userFirstName = 'Required';
					}
					if (!values.userLastName) {
						errors.userLastName = 'Required';
					}
				}

				return errors;
			}}
			onSubmit={handleFormSubmit}>
			{({ submitForm, isSubmitting, values, errors }) => (
				<>
					<Box
						display='flex'
						style={{
							background: 'lightblue',
							padding: '6px',
							borderRadius: '20px',
						}}>
							<Box  onClick={()=>handleTab('traveller')} style={{ width: '100%', textAlign: 'center', color: check==='traveller'?'white':'black',
								background: check==='traveller'?'rgb(0, 55, 114)':'',padding: '2px',borderRadius: '15px',cursor:'pointer' }}>
							<span style={{fontSize:matches?12:''}}>Travellers</span>
						</Box>
						<Box onClick={()=>handleTab('agent')}
							style={{
								width: '100%',
								textAlign: 'center',
								color: check==='agent'?'white':'black',
								background: check==='agent'?'rgb(0, 55, 114)':'',
								padding: '2px',
								borderRadius: '15px',
								cursor:'pointer'
							}}>
							<span style={{fontSize:matches?12:''}}>Agent</span>
						</Box>
						
					</Box>
					<Box className={classes.tab}>
						<Tabs
							value={tabvalue}
							TabIndicatorProps={{
								style: {
									display: 'none',
								},
							}}
							textColor='#7B95AB'
							variant='scrollable'
							scrollButtons='auto'
							aria-label='scrollable auto tabs example'>
							<Tab
								label='LOGIN'
								classes={{
									wrapper:
										tabvalue === 0 ? classes.tabBorderColor : classes.tabBorder,
								}}
								style={{ paddingLeft: 0 }}
								onClick={async () => {
									await setValue(0);
									// validateForm(values);
								}}
							/>
							<Tab
								label='SIGN UP'
								classes={{
									wrapper:
										tabvalue === 1 ? classes.tabBorderColor : classes.tabBorder,
								}}
								onClick={async () => {
									await setValue(1);
								}}
							/>
						</Tabs>
					</Box>
					<Form>
						{tabvalue === 0 && (
							<>
								<Field
									style={{ marginTop: 60 }}
									component={TextField}
									name='email'
									type='email'
									variant='outlined'
									label='Email ID'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<AccountCircle />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								{/* <TitleText two textStyle={{ marginTop: 10, fontSize: 19 }}>
									Password
								</TitleText> */}
								<Field
									style={{ marginTop: 10 }}
									component={TextField}
									name='password'
									type='password'
									variant='outlined'
									label='Password'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Box textAlign='right' onClick={()=>{history.push('/resetpassword');dispatch(toggleLoginModal(false));}} style={{cursor:'pointer'}}>
									<BodyText three textStyle={{ color: 'red' }}>
										Forgot your Password?
									</BodyText>
								</Box>
								<Button
									className={classes.createButton}
									// onClick={() => submitForm(values)}
									onClick={submitForm}
									style={{ marginTop: 10, fontSize: 16 }}
									disabled={isSubmitting}>
									LOGIN
								</Button>
							</>
						)}
						{tabvalue === 1 && (
							<>
								<Field
									component={TextField}
									name='userFirstName'
									type='text'
									variant='outlined'
									label='First Name'
									error={errors.userFirstName}
									style={{ marginTop: 10 }}
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<AccountCircle />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Field
									component={TextField}
									name='userLastName'
									type='text'
									variant='outlined'
									label='Last Name'
									error={errors.userLastName}
									style={{ marginTop: 10 }}
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<AccountCircle />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>

								<Field
									component={TextField}
									name='email2'
									type='email'
									variant='outlined'
									label='Email ID'
									error={errors.email2}
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<EmailIcon />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Field
									component={TextField}
									name='phone2'
									type='number'
									variant='outlined'
									label='Mobile No.'
									error={errors.phone2}
									// style={{ marginTop: 20 }}
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<PhoneIcon />
												<BodyText>+91</BodyText>
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Field
									component={TextField}
									name='password2'
									type='password'
									variant='outlined'
									error={errors.password2}
									label='Password'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Field
									component={TextField}
									name='confirm_password2'
									type='password'
									variant='outlined'
									error={errors.confirm_password2}
									label='Confirm Password'
									// style={{ marginBottom: 10 }}
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon />
											</InputAdornment>
										),
									}}
									inputProps={{
										style: { padding: 14, fontSize: 16 },
									}}
								/>
								<Button
									className={classes.createButton}
									onClick={submitForm}
									style={{ marginTop: 5, fontSize: 14 }}
									disabled={isSubmitting}>
									CREATE ACCOUNT
								</Button>
							</>
						)}
					</Form>
				</>
			)}
		</Formik>
	);
};

export default LoginForm;
