import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Box,
  InputAdornment,
  Tabs,
  Tab,
  withStyles,
  FormControlLabel,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { flightTicketDispatch, forgotPasswordDispatch, forgotPasswordTravellerDispatch } from "../reducers/UserReducer";
const useStyles = makeStyles((theme) => ({
  selectField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid rgba(0, 50, 91, 0.5)",
      },
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#F6F8FB",
      margin: 0,
      paddingTop: 10,
    },
    "& .MuiSelect-icon": {
      color: "#E61855",
    },
    "& div": {
      fontSize: 18,
    },
    "& input": {
      padding: "12.5px 14px",
    },
    "& .MuiSelect-select": {
      padding: "12.5px 14px",
    },

    backgroundColor: "white",
    marginBottom: 20,
    width: "100%",
    marginTop: 10,
    borderRadius: 15,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      marginTop: 8,
    },
  },
  InputPropsStyle: {
    backgroundColor: "#F6F8FB",
    borderRadius: 8,
    fontSize: 18,
  },
  inputPropsStyle: {
    // padding: 14,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F6F8FB",
  },
  createButton: {
    backgroundColor: "#0071cc",
    color: "white",
    borderColor: "#0071cc",
    borderRadius: 15,
    width: "100%",
    padding: 14,
    fontSize: 16,
    marginBottom: 20,
    "&:hover": {
      backgroundColor: "#0071cc",
    },
  },
  tabBorder: {
    borderBottom: "2px solid transparent",
    color: "#7B95AB",
    fontWeight: 400,
  },
  tabBorderColor: {
    borderBottom: "2px solid #313131",
    color: "#00325B",
    fontWeight: 700,
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      "& .MuiTab-root": {
        minWidth: 241,
        fontSize: 22,
      },
    },
  },
}));
// const country = ['INDIA', 'SRILANKA'];
const GreenCheckbox = withStyles({
  root: {
    color: "#39c9bb",
    "&$checked": {
      color: "#39c9bb",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const country = ["Jharkhand", "Kolkata"];

const ResetPasswordForm = () => {
  const classes = useStyles();
  // const theme = useTheme();
  const [tabvalue, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleCheck = (event) => {
    setCheck(event.target.checked);
  };
  const { forgotuser } = useSelector((state) => state.ui);
  const [currentStep, SetCurrentStep] = useState("Step 1");

  const handleFormSubmit = (data, actions) => {
    console.log("Data", data);
    if(forgotuser==='agent'){
      dispatch(forgotPasswordDispatch(data))
    }else{
      dispatch(forgotPasswordTravellerDispatch(data))
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          userEmail: "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, isSubmitting, values, errors }) => (
          <>
            {" "}
            <Form>
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 20 }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5>Enter Email ID</h5>
                  <Field
                    component={TextField}
                    name="userEmail"
                    type="text"
                    variant="outlined"
                    className={classes.selectField}
                    InputProps={{
                      className: classes.InputPropsStyle,
                    }}
                    inputProps={{
                      className: classes.inputPropsStyle,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    className={classes.createButton}
                    onClick={submitForm}
                    style={{ marginTop: 5, fontSize: 14 }}
                    // disabled={isSubmitting}
                  >
                    RESET
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
