import React, { useEffect, useState } from 'react';
import {
	Dialog,
	IconButton,
	DialogTitle,
	DialogContent,
	makeStyles,
	DialogContentText,
	Box,
	Grid,
	Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import HeadlineText from './HeadlineText';
// import AddUserForm from '../../forms/AddUserForm';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { toggleFlightModal } from '../../reducers/UiReducer';
import LoginForm from './LoginForm';
import TitleText from './TitleText';
import BodyText from './BodyText';


const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
		'&MuiDialog-paperScrollPaper': {
			borderRadius: 5,
		},
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(3),
		padding: 0,
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
}));

const FlightDetailModal = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const {
		search,
		onewayflightdata,
		fareRule,
		fareRuleEtrav,
		onewayflightdataEtrav,
		tripjack,
		flightreturn,
		TJKRound,
		roundTripEtrav,
		flightreturnInt,
		currency
	} = useSelector((state) => state.home);
	const { flightId, etravflightFare, selectedReturn, RflightId } = useSelector(
		(state) => state.ui
	);
	const [flight, setFlight] = useState(true);
	const [baggage, setBaggage] = useState(false);
	const [fare, setFare] = useState(false);
	const [fareDetail, setDetail] = useState(false);
	const { createflight,curr } = useSelector((state) => state.ui);
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const handleClose = () => {
		dispatch(toggleFlightModal(false));
		setFlight(true);
		setBaggage(false);
		setFare(false);
		setDetail(false);
	};
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	let appnt = [];
	let intF = [];
	let etrav = [];
	let tjk = [];
	let returnTBO = [];
	let returnTJK = [];
	let returnEtrav = [];
	const Convert = Number(curr==='INR'?currency?.conversion_rates.INR:curr==='USD'?currency?.conversion_rates.USD:1)
	if (onewayflightdata) {
		console.log('first');
		onewayflightdata.result &&
			onewayflightdata.result.Response &&
			onewayflightdata.result.Response.Results &&
			onewayflightdata.result.Response.Results.length > 0 &&
			onewayflightdata.result.Response.Results[0].map((app, index) => {
				if (app.ResultIndex === flightId) {
					appnt.push({
						...app,
					});
				}
			});
	}
	if (flightreturnInt) {
		console.log('first');
		flightreturnInt.result &&
			flightreturnInt.result.Response &&
			flightreturnInt.result.Response.Results &&
			flightreturnInt.result.Response.Results.length > 0 &&
			flightreturnInt.result.Response.Results[0].map((app, index) => {
				if (app.ResultIndex === flightId) {
					intF.push({
						...app,
					});
				}
			});
	}
	if (onewayflightdataEtrav) {
		onewayflightdataEtrav.result &&
			onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
			onewayflightdataEtrav.result.TripDetails[0].Flights.map((item, index) => {
				if (item.Fares.Fare_Id === flightId) {
					etrav.push({
						...item,
					});
				}
			});
	}
	if (tripjack) {
		tripjack?.result &&
			tripjack?.result?.searchResult?.tripInfos &&
			tripjack?.result?.searchResult?.tripInfos?.ONWARD?.length > 0 &&
			tripjack?.result?.searchResult?.tripInfos?.ONWARD?.map((item, index) => {
				if (item.totalPriceList.id === flightId) {
					tjk.push({
						...item,
					});
				}
			});
	}
	if (flightreturn) {
		console.log('first');
		flightreturn.result &&
			flightreturn.result.Response &&
			flightreturn.result.Response.Results &&
			flightreturn.result.Response.Results.length > 0 &&
			flightreturn.result.Response.Results[1].map((app, index) => {
				if (app.ResultIndex === RflightId) {
					returnTBO.push({
						...app,
					});
				}
			});
	}
	if (flightreturn) {
		console.log('first');
		flightreturn.result &&
			flightreturn.result.Response &&
			flightreturn.result.Response.Results &&
			flightreturn.result.Response.Results.length > 0 &&
			flightreturn.result.Response.Results[0].map((app, index) => {
				if (app.ResultIndex === RflightId) {
					returnTBO.push({
						...app,
					});
				}
			});
	}
	if (TJKRound) {
		TJKRound.result &&
			TJKRound.result.searchResult.tripInfos &&
			TJKRound.result.searchResult.tripInfos.ONWARD.length > 0 &&
			TJKRound.result.searchResult.tripInfos.ONWARD.map((item, index) => {
				if (item.totalPriceList.id === RflightId) {
					returnTJK.push({
						...item,
					});
				}
			});
	}
	if (TJKRound) {
		TJKRound.result &&
			TJKRound.result.searchResult.tripInfos &&
			TJKRound.result.searchResult.tripInfos.RETURN.length > 0 &&
			TJKRound.result.searchResult.tripInfos.RETURN.map((item, index) => {
				if (item.totalPriceList.id === RflightId) {
					returnTJK.push({
						...item,
					});
				}
			});
	}
	if (roundTripEtrav) {
		roundTripEtrav.result &&
			roundTripEtrav.result.TripDetails[0].Flights.length > 0 &&
			roundTripEtrav.result.TripDetails[0].Flights.map((item, index) => {
				if (item.Fares.Fare_Id === RflightId) {
					returnEtrav.push({
						...item,
					});
				}
			});
	}
	if (roundTripEtrav) {
		roundTripEtrav.result &&
			roundTripEtrav.result.TripDetails[1].Flights.length > 0 &&
			roundTripEtrav.result.TripDetails[1].Flights.map((item, index) => {
				if (item.Fares.Fare_Id === RflightId) {
					returnEtrav.push({
						...item,
					});
				}
			});
	}
	// if (roundTripEtrav) {
	// 	console.log('first');
	// 	roundTripEtrav.result &&
	// 		roundTripEtrav.result.TripDetails &&
	// 		roundTripEtrav.result.TripDetails.Results &&
	// 		roundTripEtrav.result.TripDetails.Results.length > 0 &&
	// 		roundTripEtrav.result.TripDetails.Results[1].map((app, index) => {
	// 			if (app.ResultIndex === RflightId) {
	// 				returnTBO.push({
	// 					...app,
	// 				});
	// 			}
	// 		});
	// }
	// useEffect(() => {
	// 	console.log('Hello Hi');
	// }, [createflight]);
	console.log(flightId);
	console.log('intF', intF);
	console.log('Etrav', etrav);
	console.log('TJK', returnTJK);
	console.log('TBO', returnTBO);
	// let tab =
	// 	!fareRule === null
	// 		? `${
	// 				fareRule &&
	// 				fareRule.result &&
	// 				fareRule.result.Response &&
	// 				fareRule.result.Response.FareRules[0].FareRuleDetail
	// 		  }`
	// 		: '<h1>hello</h1>';
	// document.getElementById('show').innerHTML = tab;
	return (
		<Dialog
			PaperProps={{
				style: { borderRadius: 20, maxWidth: 1500 },
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={createflight}>
			<DialogTitle id='customized-dialog-title' onClose={handleClose}>
				<Box textAlign='center'>
					<Box display='flex' justifyContent='space-evenly'>
						<IconButton
							aria-label='close'
							className={classes.closeButton}
							onClick={handleClose}>
							<CloseIcon style={{ color: '#0035F0' }} fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					<Box display='flex'>
						<Box
							onClick={() => {
								setFlight(true);
								setBaggage(false);
								setFare(false);
								setDetail(false);
							}}
							style={{
								display: 'block',
								cursor: 'pointer',
								padding: '8px 15px',
								margin: '0 5px 0 0',
								background: flight ? 'white' : '#f8f7f3',
								borderBottom: flight
									? '2px solid #39c9bb'
									: '2px solid #dfd39d',
							}}>
							<TitleText three>Flight Details</TitleText>
						</Box>
						<Box
							onClick={() => {
								setFlight(false);
								setBaggage(false);
								setFare(false);
								setDetail(true);
							}}
							style={{
								display: 'block',
								cursor: 'pointer',
								padding: '8px 15px',
								margin: '0 5px 0 0',
								background: fareDetail ? 'white' : '#f8f7f3',
								borderBottom: fareDetail
									? '2px solid #39c9bb'
									: '2px solid #dfd39d',
							}}>
							<TitleText three>Fare Details</TitleText>
						</Box>
						<Box
							onClick={() => {
								setFlight(false);
								setBaggage(true);
								setFare(false);
								setDetail(false);
							}}
							style={{
								display: 'block',
								cursor: 'pointer',
								padding: '8px 15px',
								margin: '0 5px 0 0',
								background: baggage ? 'white' : '#f8f7f3',
								borderBottom: baggage
									? '2px solid #39c9bb'
									: '2px solid #dfd39d',
							}}>
							<TitleText three>Baggage Information</TitleText>
						</Box>
						<Box
							onClick={() => {
								setFlight(false);
								setBaggage(false);
								setFare(true);
								setDetail(false);
							}}
							style={{
								display: 'block',
								cursor: 'pointer',
								padding: '8px 15px',
								margin: '0 5px 0 0',
								background: fare ? 'white' : '#f8f7f3',
								borderBottom: fare ? '2px solid #39c9bb' : '2px solid #dfd39d',
							}}>
							<TitleText three>Fare Rule</TitleText>
						</Box>
					</Box>
					{flight && appnt && appnt.length === 1 && (
						<>
							{appnt[0] &&
								appnt[0].Segments[0].map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline.AirlineCode}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{item.Airline.AirlineName}
															</TitleText>
															<BodyText three>
																{item.Airline.AirlineCode}-
																{item.Airline.FlightNumber}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin.Airport.CityName}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Origin.DepTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{timeConvert(item.Duration)}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination.Airport.CityName}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Destination.ArrTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && intF && intF.length === 1 && (
						<>
							<span style={{ marginTop: 20, color: 'blue' }}>Depart</span>
							{intF[0] &&
								intF[0].Segments[0].map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline.AirlineCode}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{item.Airline.AirlineName}
															</TitleText>
															<BodyText three>
																{item.Airline.AirlineCode}-
																{item.Airline.FlightNumber}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin.Airport.CityName}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Origin.DepTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{timeConvert(item.Duration)}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination.Airport.CityName}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Destination.ArrTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
							<span style={{ color: 'blue' }}>Return</span>
							{intF[0] &&
								intF[0].Segments[1].map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline.AirlineCode}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{item.Airline.AirlineName}
															</TitleText>
															<BodyText three>
																{item.Airline.AirlineCode}-
																{item.Airline.FlightNumber}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin.Airport.CityName}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Origin.DepTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{timeConvert(item.Duration)}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination.Airport.CityName}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Destination.ArrTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && etrav && etrav.length === 1 && (
						<>
							{etrav[0] &&
								etrav[0].Segments.map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline_Code}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{' '}
																{item.Airline_Name}
															</TitleText>
															<BodyText three>
																{item.Airline_Code}-{item.Flight_Number}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin_City}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin})
															</TitleText>
														</Box>
														<Box
															display='flex'
															alignItems='center'
															style={{ marginLeft: matches ? 5 : '' }}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Departure_DateTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{/* {timeConvert(item.Duration)} */}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination_City}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Arrival_DateTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && returnEtrav && returnEtrav.length === 1 && (
						<>
							{returnEtrav[0] &&
								returnEtrav[0].Segments.map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline_Code}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{' '}
																{item.Airline_Name}
															</TitleText>
															<BodyText three>
																{item.Airline_Code}-{item.Flight_Number}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin_City}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin})
															</TitleText>
														</Box>
														<Box
															display='flex'
															alignItems='center'
															style={{ marginLeft: matches ? 5 : '' }}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Departure_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Departure_DateTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{/* {timeConvert(item.Duration)} */}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination_City}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Arrival_DateTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Arrival_DateTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && tjk && tjk.length === 1 && (
						<>
							{tjk[0] &&
								tjk[0].sI.map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.fD.aI.code}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{' '}
																{item.fD.aI.name}
															</TitleText>
															<BodyText three>
																{item.fD.aI.code}-{item.fD.fN}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.da.city}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.da.cityCode})
															</TitleText>
														</Box>
														<Box
															display='flex'
															alignItems='center'
															style={{ marginLeft: matches ? 5 : '' }}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.dt.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.dt.substr(0, 10)
																).getDate()} ${new Date(
																	item.dt.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.dt.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{/* {timeConvert(item.Duration)} */}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.aa.city}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.aa.cityCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.at.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.at.substr(0, 10)
																).getDate()} ${new Date(
																	item.at.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.at.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && returnTJK && returnTJK.length === 1 && (
						<>
							{returnTJK[0] &&
								returnTJK[0].sI.map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.fD.aI.code}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{' '}
																{item.fD.aI.name}
															</TitleText>
															<BodyText three>
																{item.fD.aI.code}-{item.fD.fN}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.da.city}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.da.cityCode})
															</TitleText>
														</Box>
														<Box
															display='flex'
															alignItems='center'
															style={{ marginLeft: matches ? 5 : '' }}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.dt.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.dt.substr(0, 10)
																).getDate()} ${new Date(
																	item.dt.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.dt.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{/* {timeConvert(item.Duration)} */}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.aa.city}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.aa.cityCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.at.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.at.substr(0, 10)
																).getDate()} ${new Date(
																	item.at.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.at.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}
					{flight && returnTBO.length === 1 && (
						<>
							{returnTBO[0] &&
								returnTBO[0].Segments[0].map((item) => {
									return (
										<>
											<Grid
												container
												style={{
													border: '1px solid #eee',
													marginRight: 330,
													marginTop: 10,
													alignItems: 'center',
												}}>
												<Grid
													item
													xs={12}
													lg={3}
													style={{
														backgroundColor: '#f6f5f5',
														padding: 20,
													}}>
													<Box alignItems='center'>
														<img
															src={`https://nitish.musafirbazar.com/static/media/${item.Airline.AirlineCode}.gif`}
															width='50px'
															height='25px'></img>
														<Box alignItems='center' ml={'5px'}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{' '}
																{item.Airline.AirlineName}
															</TitleText>
															<BodyText three>
																{item.Airline.AirlineCode}-
																{item.Airline.FlightNumber}
															</BodyText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box alignItems='center' style={{ marginLeft: 5 }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Origin.Airport.CityName}
																{''}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Origin.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box
															display='flex'
															alignItems='center'
															style={{ marginLeft: matches ? 5 : '' }}>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Origin.DepTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
																{', '}
															</TitleText>
															<TitleText three>
																{item.Origin.DepTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<TitleText three>
															{/* {timeConvert(item.Duration)} */}
														</TitleText>
													</Box>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Box
														alignItems='center'
														style={{ marginLeft: matches ? 5 : '' }}>
														<Box display='flex' alignItems='center'>
															<TitleText
																three
																textStyle={{
																	textTransform: 'uppercase',
																	fontWeight: 500,
																}}>
																{item.Destination.Airport.CityName}
															</TitleText>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																({item.Destination.Airport.AirportCode})
															</TitleText>
														</Box>
														<Box display='flex' alignItems='center'>
															<TitleText three textStyle={{ fontWeight: 500 }}>
																{`${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	weekday: 'short',
																})} ,  ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).getDate()} ${new Date(
																	item.Destination.ArrTime.substr(0, 10)
																).toLocaleString('en-us', {
																	month: 'short',
																})}`}
															</TitleText>
															<TitleText three>
																{', '}
																{item.Destination.ArrTime.substr(11, 5)}
															</TitleText>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</>
									);
								})}
						</>
					)}

					{baggage && appnt && appnt.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{appnt[0] &&
								appnt[0].Segments[0].map((item) => {
									return (
										<>
											<Box
												mt={'20px'}
												alignItems='center'
												justifyContent='space-between'
												display='flex'>
												<Box ml={'20px'}>
													<BodyText>
														{item.Origin.Airport.AirportCode}-
														{item.Destination.Airport.AirportCode} (
														{item.Airline.AirlineCode}-
														{item.Airline.FlightNumber})
													</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText> {item.Baggage} (ADULT)</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText>{item.CabinBaggage} (ADULT)</BodyText>
												</Box>
											</Box>
											<Divider style={{ marginTop: 20 }} />
										</>
									);
								})}
						</>
					)}
					{baggage && intF && intF.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							{/* <span style={{ marginTop: 40, color: 'blue' }}>Depart</span> */}
							<Divider style={{ marginTop: 20 }} />
							{intF[0] &&
								intF[0].Segments[0].map((item) => {
									return (
										<>
											<Box
												mt={'20px'}
												alignItems='center'
												justifyContent='space-between'
												display='flex'>
												<Box ml={'20px'}>
													<BodyText>
														{item.Origin.Airport.AirportCode}-
														{item.Destination.Airport.AirportCode} (
														{item.Airline.AirlineCode}-
														{item.Airline.FlightNumber})
													</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText> {item.Baggage} </BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText>{item.CabinBaggage} </BodyText>
												</Box>
											</Box>
											<Divider style={{ marginTop: 20 }} />
										</>
									);
								})}
							{/* <span style={{ marginTop: 10, color: 'blue' }}>Return</span> */}
							<Divider style={{ marginTop: 20 }} />

							{intF[0] &&
								intF[0].Segments[1].map((item) => {
									return (
										<>
											<Box
												mt={'20px'}
												alignItems='center'
												justifyContent='space-between'
												display='flex'>
												<Box ml={'20px'}>
													<BodyText>
														{item.Origin.Airport.AirportCode}-
														{item.Destination.Airport.AirportCode} (
														{item.Airline.AirlineCode}-
														{item.Airline.FlightNumber})
													</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText> {item.Baggage} </BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText>{item.CabinBaggage} </BodyText>
												</Box>
											</Box>
											<Divider style={{ marginTop: 20 }} />
										</>
									);
								})}
						</>
					)}
					{baggage && tjk && tjk.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<BodyText>
										{`${tjk[0].sI[0].da.cityCode}-${tjk[0].sI[0].aa.cityCode}`}
									</BodyText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<BodyText>
										{' '}
										{tjk[0].totalPriceList.fd?.ADULT?.bI?.iB}
									</BodyText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<BodyText>{tjk[0].totalPriceList.fd?.ADULT?.bI?.cB}</BodyText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
						</>
					)}
					{baggage && returnTJK && returnTJK.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<BodyText>
										{`${returnTJK[0].sI[0].da.cityCode}-${returnTJK[0].sI[0].aa.cityCode}`}
									</BodyText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<BodyText>
										{' '}
										{returnTJK[0].totalPriceList.fd?.ADULT?.bI?.iB}
									</BodyText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<BodyText>
										{returnTJK[0].totalPriceList.fd?.ADULT?.bI?.cB}
									</BodyText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
						</>
					)}
					{baggage && returnTBO && returnTBO.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={matches ? '20px' : '150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{returnTBO[0] &&
								returnTBO[0].Segments[0].map((item) => {
									return (
										<>
											<Box
												mt={'20px'}
												alignItems='center'
												justifyContent='space-between'
												display='flex'>
												<Box ml={'20px'}>
													<BodyText>
														{item.Origin.Airport.AirportCode}-
														{item.Destination.Airport.AirportCode} (
														{item.Airline.AirlineCode}-
														{item.Airline.FlightNumber})
													</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText> {item.Baggage} (ADULT)</BodyText>
												</Box>
												<Box ml={matches ? '20px' : '150px'}>
													<BodyText>{item.CabinBaggage} (ADULT)</BodyText>
												</Box>
											</Box>
											<Divider style={{ marginTop: 20 }} />
										</>
									);
								})}
						</>
					)}
					{baggage && etrav && etrav.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={'150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={'150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{/* {etrav[0] &&
								etrav[0].Fares.FareDetails.map((item, index) => {
									return (
										<> */}
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<BodyText></BodyText>
								</Box>
								<Box ml={'150px'}>
									<BodyText>
										{' '}
										{/* {etrav[0].Fares.FareDetails[0].Free_Baggage.Check_In_Baggage} */}
										{/* {index === 0
															? '(Adult)'
															: index === 1
															? '(Child)'
															: index === 2
															? '(Infant)'
															: ''} */}
									</BodyText>
								</Box>
								<Box ml={'150px'}>
									<BodyText>
										{/* {etrav[0].Fares.FareDetails[0].Free_Baggage.Hand_Baggage}{' '} */}
										{/* {index === 0
															? '(Adult)'
															: index === 1
															? '(Child)'
															: index === 2
															? '(Infant)'
															: ''} */}
									</BodyText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{/* </>
									);
								})} */}
						</>
					)}
					{baggage && returnEtrav && returnEtrav.length === 1 && (
						<>
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Flight Sector
									</TitleText>
								</Box>
								<Box ml={'150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Check in Baggage
									</TitleText>
								</Box>
								<Box ml={'150px'}>
									<TitleText
										textStyle={{
											fontFamily: 'Josefin Sans, sans-serif',
											fontWeight: 500,
										}}>
										Cabin Baggage
									</TitleText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{/* {etrav[0] &&
								etrav[0].Fares.FareDetails.map((item, index) => {
									return (
										<> */}
							<Box
								mt={'20px'}
								alignItems='center'
								justifyContent='space-between'
								display='flex'>
								<Box ml={'20px'}>
									<BodyText></BodyText>
								</Box>
								<Box ml={'150px'}>
									<BodyText>
										{' '}
										{/* {etrav[0].Fares.FareDetails[0].Free_Baggage.Check_In_Baggage} */}
										{/* {index === 0
															? '(Adult)'
															: index === 1
															? '(Child)'
															: index === 2
															? '(Infant)'
															: ''} */}
									</BodyText>
								</Box>
								<Box ml={'150px'}>
									<BodyText>
										{/* {etrav[0].Fares.FareDetails[0].Free_Baggage.Hand_Baggage}{' '} */}
										{/* {index === 0
															? '(Adult)'
															: index === 1
															? '(Child)'
															: index === 2
															? '(Infant)'
															: ''} */}
									</BodyText>
								</Box>
							</Box>
							<Divider style={{ marginTop: 20 }} />
							{/* </>
									);
								})} */}
						</>
					)}
					{fareDetail && etrav && etrav.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Basic Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(etrav[0].Fares.Basic_Amount))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6
													style={{
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}>
													AirportTax Amount:
												</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(etrav[0].Fares.AirportTax_Amount))))}
												</h6>
											</Box>
											{/* <Box display='flex'>
												<h6>Service Fee Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{etrav[0].Fares[0].FareDetails[0].Currency_Code}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Math.round(
														etrav[0].Fares[0].FareDetails[0].Service_Fee_Amount
													)}
												</h6>
											</Box> */}

											<Box display='flex'>
												<h6>Total Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(etrav[0].Fares.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && returnEtrav && returnEtrav.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Basic Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(returnEtrav[0].Fares.Basic_Amount))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6
													style={{
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}>
													AirportTax Amount:
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(
														Math.round(returnEtrav[0].Fares.AirportTax_Amount)
													)))}
												</h6>
											</Box>
											{/* <Box display='flex'>
												<h6>Service Fee Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{etrav[0].Fares[0].FareDetails[0].Currency_Code}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Math.round(
														etrav[0].Fares[0].FareDetails[0].Service_Fee_Amount
													)}
												</h6>
											</Box> */}

											<Box display='flex'>
												<h6>Total_Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(
														Math.round(returnEtrav[0].Fares.Total_Amount)
													)))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && appnt && appnt.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Base Fare:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(appnt[0].Fare.Basic_Amount))))}
												</h6>
											</Box>

											<Box display='flex'>
												<h6>AirportTax Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(appnt[0].Fare.AirportTax_Amount))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6>Grand Total:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(appnt[0].Fare.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && intF && intF.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Base Fare:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(intF[0].Fare.Basic_Amount))))}
												</h6>
											</Box>

											<Box display='flex'>
												<h6>AirportTax Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(intF[0].Fare.AirportTax_Amount))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6>Grand Total:</h6>
												<h6 style={{ marginLeft: 10 }}>
													{'₹'}
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(intF[0].Fare.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && returnTBO && returnTBO.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Base Fare:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(returnTBO[0].Fare.Basic_Amount))))}
												</h6>
											</Box>

											<Box display='flex'>
												<h6>AirportTax Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(returnTBO[0].Fare.AirportTax_Amount))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6>Grand Total:</h6>
												<h6 style={{ marginLeft: 10 }}>
												{'₹'}
												</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(returnTBO[0].Fare.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && tjk && tjk.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Base Fare:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(tjk[0].totalPriceList.fd.Basic_Amount))))}
												</h6>
											</Box>

											<Box display='flex'>
												<h6>AirportTax Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(
														tjk[0].totalPriceList.fd.AirportTax_Amount
													))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6>Grand Total:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(tjk[0].totalPriceList.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fareDetail && returnTJK && returnTJK.length === 1 && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<Box display='flex' justifyContent='space-between'>
										<Box>
											<h6 style={{ fontWeight: 'bold' }}>Fare Details</h6>
											<Box display='flex'>
												<h6>Base Fare:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(
														returnTJK[0].totalPriceList.fd.Basic_Amount
													))))}
												</h6>
											</Box>

											<Box display='flex'>
												<h6>AirportTax Amount:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 5 }}>
													{Number(Math.round(Number(Math.round(
														returnTJK[0].totalPriceList.fd.AirportTax_Amount
													))))}
												</h6>
											</Box>
											<Box display='flex'>
												<h6>Grand Total:</h6>
												<h6 style={{ marginLeft: 10 }}>{'₹'}</h6>
												<h6 style={{ marginLeft: 10 }}>
													{Number(Math.round(Number(Math.round(returnTJK[0].totalPriceList.Total_Amount))))}
												</h6>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{fare && fareRuleEtrav && (
						<>
							<Box>
								<Box style={{ marginTop: 20, padding: 24 }}>
									<div
										dangerouslySetInnerHTML={{
											__html:
												fareRuleEtrav &&
												fareRuleEtrav.result &&
												fareRuleEtrav.result.FareRules[0].FareRuleDesc,
										}}
									/>
								</Box>
							</Box>
						</>
					)}
					{fare &&
						fareRule &&
						fareRule.result &&
						fareRule.result.Response &&
						fareRule.result.Response.FareRules[0] && (
							<>
								<Box>
									<Box style={{ marginTop: 20, padding: 24 }}>
										<div
											style={{ fontSize: 11 }}
											dangerouslySetInnerHTML={{
												__html:
													fareRule &&
													fareRule.result &&
													fareRule.result.Response &&
													fareRule.result.Response.FareRules[0].FareRuleDetail,
											}}
										/>
									</Box>
								</Box>
							</>
						)}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default FlightDetailModal;
