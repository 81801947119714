import React from 'react';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import { Box, Grid } from '@material-ui/core';
import Footer from './Footer';
const item = [
	{
		image:
			'https://img.happyeasygo.com/file/share_order/_picture/1595470252746.png',
		name: 'Spice Jet',
		link: 'https://book.spicejet.com/SearchWebCheckin.aspx',
	},
	{
		image:
			'https://img.happyeasygo.com/file/share_order/_picture/1595470469648.png',
		name: 'IndiGo',
		link: 'https://www.goindigo.in/web-check-in.html',
	},
	{
		image:
			'https://img.happyeasygo.com/file/share_order/_picture/1595469935087.png',
		name: 'AirIndia',
		link: 'https://www.airindia.in/',
	},
	{
		image:
			'https://img.happyeasygo.com/file/share_order/_picture/1595484030193.png',
		name: 'AirIndia Express',
		link: 'https://travel.airindia.in/ssci/identification',
	},
];
const CheckInPage = () => {
	return (
		<>
			<HeaderSection />
			<Grid container style={{ marginTop: 100, marginBottom: 100 }}>
				<Grid item xs={4}></Grid>
				<Grid item xs={4}>
					<h2>Select Airline</h2>
					{item.map((i, index) => {
						return (
							<Box
								justifyContent={'space-between'}
								display='flex'
								style={{
									border: '1px solid #dcdcdc',
									// paddingLeft: 20,
									marginTop: index === 0 ? 5 : 20,
									// paddingRight: 20,
									padding: 20,
								}}
								alignItems={'center'}>
								<Box>
									<img src={i.image} style={{ height: 60 }} />
									<span
										style={{
											fontSize: 22,
											marginLeft: 20,
										}}>
										{i.name}
									</span>
								</Box>

								<a
									style={{
										background: '#f79c1f',
										padding: 10,
										borderRadius: 10,
									}}
									href={i.link}
									target='_blank'>
									<span style={{ color: 'white', fontWeight: 'bold' }}>
										Go Airline
									</span>
								</a>
							</Box>
						);
					})}
				</Grid>
				<Grid item xs={4}></Grid>
			</Grid>
			<Footer />
		</>
	);
};

export default CheckInPage;
