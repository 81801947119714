import React, { useState } from 'react';
import { Grid, Box, Button,Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import BodyText from '../../components/reusableComponents/BodyText';
import {
	makeStyles,
	Container,
	TextField as TextField2,
} from '@material-ui/core';
import '../../main.css';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import LoadingButton from '@mui/lab/LoadingButton';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation } from 'react-router-dom';
// import HeaderSection from './HeaderSection';
import { toggleEditTicketModal } from '../../reducers/UiReducer';
// import EditTicketPriceModal from './EditTicketPriceModal';
import { FlightMailTicketDispatch } from '../../reducers/HomeReducer';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import PrintTicketForm from '../../form/PrintTicketForm';
import ResetPasswordForm from '../../form/ResetPasswordForm';
import { forgotPasswordDispatch } from '../../reducers/UserReducer';
import SuccessModal from '../../components/reusableComponents/SuccessModal';
import ErrorModal from '../../components/reusableComponents/ErrorModal';
const useStyles = makeStyles((theme) => ({
	table: {
		background: '#fff',
		margin: '0px auto',
		width: '850px !important',
		padding: '30px 20px',
		webkitPrintColorAdjust: 'exact',
	},
	table2: {
		boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
		background: '#fff',
		borderSpacing: 0,
		padding: 0,
		margin: 0,
	},
	table3: {
		width: '100%',
		padding: 0,
		margin: 0,
	},
	row: {
		width: '60%',
		padding: '15px 40px',
	},
	row2: {
		width: '50%',
		border: '1px solid #333',
		borderLeft: 0,
		padding: '8px',
	},
	row3: {
		width: '50%',
		borderTop: '1px solid #333',
		borderBottom: '1px solid #333',
		padding: '5px',
	},
}));

const ResetPassword = () => {
	const [print, setPrint] = useState(false);
	const { ticket } = useSelector((state) => state.home);
	const { editprice } = useSelector((state) => state.ui);
	const { etrav } = useSelector((state) => state);
	console.log('ticket', ticket);
	// useEffect(() => {
	//     dispatch(FlightTicketDispatch());
	// },[])
	const location = useLocation();
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	console.log('Location', location);
	// const handleClick = () => {
	// 	setLoading(true);
	// 	let formData = {
	// 		userEmail: location.state.mailBody.formData.email,
	// 	};
	// 	dispatch(forgotPasswordDispatch(formData))
	// };
	return (
		<>
			<HeaderSection id={1} />
			<section
				style={{ background: '#0c2f55', color: 'white', marginTop: 50 }}
				id='ticket2'>
				<Grid container>
					<Grid item xs={8}>
						<h1>Reset Password</h1>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>
			</section>
			<Grid container style={{ paddingLeft: 20 }}>
				<Hidden only={['xs','sm']}>
				<Grid item xs={4}></Grid>
				</Hidden>
				
				<Grid item xs={12} id='ticket' lg={4}>
					<Box
						style={{
							backgroundColor: '#FF F',
							boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
							padding: 20,
						}}>
						<ResetPasswordForm/>
					</Box>
				</Grid>
				<Hidden only={['xs','sm']}>
				<Grid item xs={4}></Grid>
				</Hidden>
				
			</Grid>
			<SuccessModal/>
			<ErrorModal/>
		</>
	);
};

export default ResetPassword;
