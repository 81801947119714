import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import {
	makeStyles,
	TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setFDdate, setOrigin, setOriginO } from '../../reducers/UiReducer';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		width: '100%',
		border: '1px solid lightgrey',
		borderRadius: 4,
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 12,
			padding: '12px',
			fontWeight: 600,
		},
		'& .MuiInputAdornment-root': {
			marginRight: 14,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#39c9bb',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#39c9bb',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	auto: {
		'& input': {
			padding: '20px 18px',
		},
		'& div': {
			'& div': {
				padding: 3,
			},
		},
		'& .css-xrz6uu-MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
			padding: '11px 4px 4px 0px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiFormLabel-root': {
			marginLeft: 5,
			fontSize: 13,
		},
		'& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator':
			{
				marginTop: -10,
			},
	},
	root: {
		'& .InputFromTo': {
			'& .DayPickerInput input': {
				padding: '20px 18px',
			},
		},
	},
	j: {
		'& .MuiBox-root': {
			display: 'flex',
			alignContent: 'stretch',
			flexWrap: 'wrap',
		},
	},
	down: {
		marginTop: '385px',
		minWidth: 300,
		padding: 10,
		background: 'white',
		position: 'absolute',
		boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
		transformOrigin: 'top',
		transition: 'all .3s ease',
		'@media(min-device-width: 500px) and (max-device-width: 1120px)': {
			marginLeft: 137,
		},
	},
	dayWithDotContainer: {
		position: 'relative',
	},
	dayWithDot: {
		position: 'absolute',
		height: 0,
		width: 0,
		border: '2px solid',
		borderRadius: 4,
		borderColor: theme.palette.primary.main,
		right: '50%',
		transform: 'translateX(1px)',
		top: '80%',
	},
	new: {
		'& .css-mvmu1r': {
			marginTop: 18,
		},

		'& .MuiButtonBase-root': {
			fontSize: 20,
		},
		'& input::placeholder': {
			fontSize: '30px',
		},
	},
}));
export default function DesktopDateFd() {
	const [value, setValue] = React.useState(new Date());
	const [isOpen, setIsOpen] = React.useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('/');
	};
	dispatch(setOrigin(convert(value)));
	dispatch(setOriginO(value));
	const onOpenPicker = () => {
		onPickerViewChange(value);
	};
	let sector = [
		{ date: '2022-08-25' },
		{ date: '2022-08-26' },
		{ date: '2022-08-31' },
		,
		{ date: '2022-09-15' },
	];
	let sdate = [];
	if (sector) {
		sector.map((i, index) => {
			if (i.date != null) {
				sdate.push({
					...i,
					dat: new Date(`${i.date}T00:00`),
				});
			}
		});
	}
	console.log('sector', sdate);
	const disableWeekends = (date) => {
		// const dateInterditesRaw = [
		// 	new Date('2022-08-25T00:00'),
		// 	new Date('2022-08-26T00:00'),
		// 	new Date('2022-08-31T00:00'),
		// 	// new Date(date.getFullYear(), 7, 8),
		// 	// new Date(date.getFullYear(), 6, 14),
		// 	// new Date(date.getFullYear(), 7, 15),
		// 	// new Date(date.getFullYear(), 10, 1),
		// 	// new Date(date.getFullYear(), 10, 11),
		// 	// new Date(date.getFullYear(), 11, 25),
		// ];

		/* make a new array with the getTime() without it date comparaison are 
    never true  */

		const dateInterdites = sdate.map((arrVal) => {
			return arrVal.dat.getTime();
		});

		/*exclude all sunday and use the includes array method to check if the 
    date.getTime() value is 
    in the array dateInterdites */
		// date.getTime() === new Date('2022-08-25T00:00').getTime();
		return dateInterdites.includes(date.getTime());
	};
	const onPickerViewChange = async (date) => {
		const variables = {
			fromDate: date.clone().startOf('month').format('YYYY/MM/DD'),
			toDate: date.clone().endOf('month').format('YYYY/MM/DD'),
		};

		// return request(url, query, variables)
		// 	.then((response) => {
		// 		setDaysWithDot(
		// 			response.data.map((day) => moment(day).format('YYYY/MM/DD'))
		// 		);
		// 	})
		// 	.catch((err) => Logger.error(err));
	};
	const birthdays = {
		2: [789],
		8: ['Elena'],
		9: ['Irene'],
		12: ['Paolo', 'Giorgia'],
		18: ['Claudia'],
		22: ['Maria', 'Luigi'],
		25: ['Simone'],
		26: ['Marta'],
	};

	function renderDay(day, dayComponent, selectedDate) {
		const date = day.getDate();
		const dateStyle = {
			position: 'absolute',
			// color: 'lightgray',
			bottom: 0,
			// right: 0.2,
			marginLeft: 16,
			marginTop: 10,
			fontSize: 16,
		};
		const birthdayStyle = { fontSize: '0.8em' };
		const cellStyle = {
			height: 30,
			width: 40,
			position: 'relative',
		};
		console.log(day);
		return (
			<div style={cellStyle} onClick={(day) => console.log(day.target)}>
				<div style={dateStyle}>{date}</div>
				<div style={{ fontSize: '8px', marginTop: 26, marginLeft: 10 }}>
					4569
				</div>
				{/* {birthdays[date] &&
					birthdays[date].map((name, i) => (
						<div key={i} style={birthdayStyle}>
							{name}
						</div>
					))} */}
			</div>
			// <div role='cell'>
			// 	<button
			// 		className='MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin css-bkrceb-MuiButtonBase-root-MuiPickersDay-root'
			// 		tabindex='-1'
			// 		type='button'>
			// 		{date}
			// 	</button>
			// 	{/* <span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span> */}
			// </div>
		);
	}

	return (
		<div className={classes.new}>
			{matches ? (
				<TextField
					id='date'
					label='Choose your birthdate'
					type='date'
					className={classes.selectField}
					onChange={(e) => {
						setValue(e.target.value);
						console.log(e.target.value);
						dispatch(setOriginO(e.target.value));
					}}
					// defaultValue="2017-05-24"
					InputLabelProps={{
						shrink: true,
					}}
				/>
			) : (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label='From'
						value={value}
						disablePast
						// minDate={new Date("2017-01-01")}
						// shouldDisableDate={disableWeekends}
						inputFormat='dd/MM/yyyy'
						onChange={(newValue) => {
							setValue(newValue);
							console.log(newValue);
							dispatch(setFDdate(convert(newValue)));
						}}
						open={isOpen}
						onOpen={() => setIsOpen(true)}
						onClose={() => setIsOpen(false)}
						// renderDay={(date, selectedDate, dayInCurrentMonth, dayComponent) =>
						// 	getDayElement(date, selectedDate, dayInCurrentMonth, dayComponent)
						// }
						// renderDay={renderDay}
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.selectField}
								onClick={(e) => setIsOpen(true)}
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		</div>
	);
}
