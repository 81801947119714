import { axiosInstance } from '../Axios';

const { createSlice } = require('@reduxjs/toolkit');

const FDReducers = createSlice({
	name: 'user',
	initialState: {
		success: false,
		error: null,
		loadingfd: false,
		fdresult: null,
		fdsector: null,
		fdbook: null,
		fdbookDetail: null,
	},
	reducers: {
		searchRequest(state, action) {
			state.loadingfd = true;
		},
		searchSuccess(state, action) {
			state.loadingfd = false;
			state.fdresult = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		sectorRequest(state, action) {
			state.loadingfd = true;
		},
		sectorSuccess(state, action) {
			state.loadingfd = false;
			state.fdsector = action.payload;
			state.error = null;
		},
		sectorFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		bookRequest(state, action) {
			state.loadingfd = true;
		},
		bookSuccess(state, action) {
			state.loadingfd = false;
			state.fdbook = action.payload;
			state.error = null;
		},
		bookFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		bookDetailRequest(state, action) {
			state.loadingfd = true;
		},
		bookDetailSuccess(state, action) {
			state.loadingfd = false;
			state.fdbookDetail = action.payload;
			state.error = null;
		},
		bookDetailFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
	},
});

const { actions } = FDReducers;
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};

export const {
	searchRequest,
	searchSuccess,
	searchFail,
	sectorRequest,
	sectorSuccess,
	sectorFail,
	bookRequest,
	bookSuccess,
	bookFail,
	bookDetailRequest,
	bookDetailSuccess,
	bookDetailFail,
} = actions;

export const fdSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(searchRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightsearch',
			bodyData,
			config
		);
		dispatch(searchSuccess(data));
	} catch (error) {
		dispatch(
			searchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdSectorDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(sectorRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightroutes',
			bodyData,
			config
		);
		dispatch(sectorSuccess(data));
	} catch (error) {
		dispatch(
			sectorFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdBookDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(bookRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightbook',
			bodyData,
			config
		);
		dispatch(bookSuccess(data));
		if (data?.result?.status === 'success') {
			let form = {
				bookingId: data.result.booking_id,
			};
			dispatch(fdBookDetailDispatch(form, history));
		}
	} catch (error) {
		dispatch(
			bookFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdBookDetailDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(bookDetailRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightbookingdetail',
			bodyData,
			config
		);
		dispatch(bookDetailSuccess(data));
		if (data.status === 200) {
			history.push({
				pathname: '/ticketfd',
				state: data.result.data,
			});
		}
	} catch (error) {
		dispatch(
			bookDetailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default FDReducers;
