import React, { useEffect } from 'react';
import { Grid, Container, Box, Divider } from '@material-ui/core';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Footer from './Footer';
import Meta from '../../components/reusableComponents/Meta';
import {
	fetchFooterMenu,
	fetchFooterSub_Menu,
} from '../../reducers/HomeReducer';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
const data = [
	{
		title: 'Quick & Easy Bookings',
		desc: 'Booking a taxi for yourself in Hire Click N Book is very easy, just say it is time to put your finger. Your order will be booked in seconds. You don’t even need to read rocket science to do it.',
	},
	{
		title: 'Expert Trip Planners',
		desc: 'Every volunteer we have is famous in this field, who will be your companion in your journey, who will be ready to help you in every way related to travel. You can connect with us anytime by phone, we will be happy to help you.',
	},
	{
		title: 'Easy Tour Customization',
		desc: 'Our team ensures that you are not inconvenienced in any way in your journey. We have trips ready for people of all ages who are prescribed by our experts, but still, we provide you the service under which you can plan your tour yourself.',
	},
	{
		title: 'Quick & Easy Bookings',
		desc: 'Booking a taxi for yourself in Hire Click N Book is very easy, just say it is time to put your finger. Your order will be booked in seconds. You don’t even need to read rocket science to do it.',
	},
	{
		title: 'Anytime Support',
		desc: 'The numbers given on our website are ready to listen to you all the time. Every person can call 24 hours and 7 days at any time. Our expert members and dedicated team feel jovial while helping the customers. You can contact us at any time for the travel bookings and for the proper guidance regarding your destined site. Click N Book is always there to help you and guide you 24/7.',
	},
	{
		title: 'Expert Cab Drivers',
		desc: 'We make regular efforts to make your journey memorable. For this we also ensure that the members working with us have experts in their fields so that the passengers do not face any problem. You will find all our drivers full of information and energy which will help you all the time.',
	},
	{
		title: 'Knowledge-Base Guides',
		desc: 'Whatever you should lose all sense of direction in Himachal disputants, it will give by Hire Click N Book. Our movement specialists will reveal to you the things of Himachal that no one will let you know. Try not to stress over this, regardless of whether your cell phone isn’t supporting you, our specialists and we will bolster you. Our specialists are accessible for you 24 hours. Our specialists can dodge no issue identified with the movement. Our specialists will take care of each issue when necessary. So come and pick the best travel visit specialists in Himachal Pradesh. We manage your joy.',
	},
];

const data2 = [
	{
		i: 'fas fa-lock',
		title: '100% Secure',
		desc: 'Moving your card details to a much more secured place.',
	},
	{
		i: 'fas fa-thumbs-up',
		title: 'Trust pay',
		desc: '100% Payment Protection. Easy Return Policy.',
	},
	{
		i: 'fas fa-bullhorn',
		title: 'Refer & Earn',
		desc: 'Invite a friend to sign up and earn up to Rs 100.',
	},
	{
		i: 'far fa-life-ring',
		title: '24X7 Support',
		desc: "We're here to help. Have a query and need help ?",
	},
];
const AboutusPage = () => {
	const dispatch = useDispatch();
	const { footer_submenu } = useSelector((state) => state.home);
	useEffect(() => {
		window.scrollTo(0, 0);
		let form = {};
		dispatch(fetchFooterMenu(form));
		dispatch(fetchFooterSub_Menu(form));
	}, []);
	const lo̥cati̥̥̥on = useLocation();
	console.log('Location', lo̥cati̥̥̥on);
	let data = [];
	if (footer_submenu) {
		if (footer_submenu && footer_submenu?.result?.length > 0) {
			footer_submenu?.result?.map((i) => {
				if (i.link_url === lo̥cati̥̥̥on?.pathname.split('/')[2]) {
					data.push({
						...i,
					});
				}
			});
		}
	}

	console.log('data', data);
	return (
		<>
			{/* <Meta
          title={`Mariners Forex | ${data
			? data[0]?.name
			: lo̥cati̥̥̥on && lo̥cati̥̥̥on.state && lo̥cati̥̥̥on.state.name}`}
          description={
            "Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site."
          }
          keywords={
            "online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations"
          }
        /> */}
			<HeaderSection id={1} />
			<Box style={{ background: '#0c2f55', color: 'white', marginTop: 50 }}>
				<Grid container>
					<Grid item xs={8}>
						<h1 style={{ marginLeft: 20 }}>{lo̥cati̥̥̥on.state.name}</h1>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>
			</Box>
			<Container
				style={{
					background: '#FFF',
					boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
					borderRadius: '.25rem',
					padding: '1.5em',
					marginTop: 20,
				}}>
				<div
					style={{ fontSize: 11, fontWeight: 'bold' }}
					dangerouslySetInnerHTML={{
						__html: lo̥cati̥̥̥on.state.content,
					}}
				/>
			</Container>
			{/* <section style={{ background: '#0c2f55', color: 'white', marginTop: 50 }}>
				<Grid container>
					{data2.map((item) => {
						return (
							<Grid item xs={3}>
								<Box display='flex' alignItems='baseline'>
									<i class={item.i} style={{ height: 50, width: 45 }}></i>
									<Box>
										<h3 style={{ color: '#f8f9fa' }}>{item.title}</h3>
										<p
											style={{
												color: '#f8f9fa',
												fontFamily: '"Poppins", sans-serif',
											}}>
											{item.desc}
										</p>
									</Box>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</section> */}
			<Container>
				<Divider style={{ marginTop: 20 }} />
			</Container>
			<Footer />
		</>
	);
};

export default AboutusPage;
