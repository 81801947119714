import React, { useState, useEffect } from 'react';
import {
	Grid,
	// TextField,
	Box,
	makeStyles,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	Popper,
	useMediaQuery,
	useTheme,
	MenuItem,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import flt from '../assets/images/flt.png';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeNew from '../components/reusableComponents/DateRangeNew';
import BodyText from '../components/reusableComponents/BodyText';
import { Form, Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { fetchCityDispatch } from '../reducers/HomeReducer';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import DesktopDateNew from '../components/reusableComponents/DesktopDateNew';
import {
	setSelectedDepart,
	setSelectedOrigin,
	setSelectedTrip,
} from '../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				// border: "1px solid #959595",
			},
		},
		// backgroundColor: 'white',
		// marginBottom: 30,
		width: '100%',
		border: '1px solid #959595',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .MuiSelect-select': {
			padding: 8,
			border: '1px solid grey',
		},
	},
	InputPropsStyle: {
		// backgroundColor: 'white',
		borderRadius: 15,
	},
	inputPropsStyle: {
		padding: 8,
		border: '1px solid lightgrey',
	},
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'grey',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
			// fontSize: '14px',
			//   fontWeight: 800,
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '.8em',
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
			// fontSize: '14px',
			// fontWeight: 800,
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
				// '& img': {
				// 	background: 'rgb(239, 51, 57)',
				// },
			},
		},
	},
}));
const SearchEngineForex = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { agentlogin } = useSelector((state) => state.user);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('buy');
	const [going, setGoing] = useState('CCU');
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const title = [
		'U.S. Dollar',
		'Australian Dollar',
		'Canadian Dollar',
		'Euro',
		'New Zealand Dollar',
		'British Pound',
		'Hong Kong Dollar',
		'Japanese Yen',
		'Kuwaiti Dinar',
		'Malaysian Ringgit',
		'Omani Rial',
		'Qatari Riyal',
		'Saudi Riyal',
		'Singapore Dollar',
		'Thai Baht',
		'UAE Dirham',
	];
	const { origin, depart, OriginO, selectedOrigin, selectedDepart, tripInfo } =
		useSelector((state) => state.ui);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const handleSubmit = (data) => {
		console.log(data);
		let formData = {
			forex_type: trip,
			amount: data.amount,
			email: data.email,
			mobile: data.mobile,
			name: data.name,
			type: data.type,
		};
		console.log('formData', formData);
		// dispatch(fetchForex(formData,history));
		// alert("Request Submiteed Successfully");
	};

	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchCityDispatch(cityData, setResult));
	};
	const handleChangeTrip = (e) => {
		setTrip(e.target.value);
		// dispatch(setSelectedTrip(e.target.value));
	};
	// useEffect(() => {
	// 	let cityData = {
	// 		keywords: 'bo',
	// 		limits: 8,
	// 	};
	// 	dispatch(fetchCityDispatch(cityData, setResult));
	// }, []);
	const PopperMy = function (props) {
		return (
			<Popper
				{...props}
				style={styles.popper}
				className={classes.popper}
				placement='bottom-start'
			/>
		);
	};

	const styles = (theme) => ({
		popper: {
			width: 'fit-content',
		},
	});
	return (
		<>
			<Formik
				initialValues={
					{
						// type: "",
						// name: "",
						// email: "",
						// amount: "",
						// mobile: "",
					}
				}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm }) => (
					<>
						<Formik
							initialValues={{
								type: '',
								name: '',
								email: '',
								amount: '',
								mobile: '',
							}}
							validate={(values) => {
								const errors = {};
							}}
							onSubmit={handleSubmit}>
							{({ submitForm, isSubmitting, setFieldValue, values }) => (
								<>
									<Form
										style={{
											background: 'white',
											// marginTop: '25px',
											paddingLeft: 20,
											paddingRight: 20,
											boxShadow: '-1px 3px 5px 0 rgb(0 0 0 / 16%)',
										}}>
										<Box className={classes.radio}>
											<FormControl onChange={handleChangeTrip}>
												<RadioGroup
													row
													aria-labelledby='demo-row-radio-buttons-group-label'
													name='row-radio-buttons-group'>
													<FormControlLabel
														value='buy'
														control={
															<Radio checked={trip == 'buy' ? true : false} />
														}
														label='Buy'
													/>
													<FormControlLabel
														value='sell'
														control={
															<Radio checked={trip == 'sell' ? true : false} />
														}
														label='Sell'
													/>
													<FormControlLabel
														value='forexcard'
														control={
															<Radio
																checked={trip == 'forexcard' ? true : false}
															/>
														}
														label='Forex Card'
													/>
													<FormControlLabel
														value='remittance'
														control={
															<Radio
																checked={trip == 'remittance' ? true : false}
															/>
														}
														label='Remittance'
													/>
												</RadioGroup>
											</FormControl>
										</Box>
										<Grid container spacing={2} alignItems='center'>
											<Grid item xs={12} md={12} lg={4}>
												<Grid container alignItems='center' spacing={2}>
													<Grid
														item
														xs={12}
														lg={6}
														style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: 23,
														}}>
														{/* <div className={classes.root} style={{ width: "100%" }}>
                         {/* <Box></Box> */}

														<Field
															component={TextField}
															select
															name='type'
															type='text'
															variant='outlined'
															className={classes.selectField}
															InputProps={{
																className: classes.InputPropsStyle,
															}}
															inputProps={{
																className: classes.inputPropsStyle,
															}}>
															{title.map((item, index) => (
																<MenuItem
																	key={index}
																	value={item}
																	style={{ padding: 5 }}>
																	{item}
																</MenuItem>
															))}
														</Field>
													</Grid>
													<Grid
														item
														xs={12}
														lg={6}
														style={{ display: 'flex', alignItems: 'center' }}>
														<div
															className={classes.root}
															style={{ width: '100%' }}>
															<span
																style={{
																	color: 'black',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Amount
															</span>
															<Field
																component={TextField}
																name='amount'
																type='number'
																// error={errors.last_nameA1}
																variant='outlined'
																// label="Enter Amount"
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: {
																		padding: 10,
																		border: '1px solid grey',
																		borderRadius: 5,
																	},
																}}
															/>
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} md={12} lg={4}>
												<Grid container alignItems='center' spacing={2}>
													<Grid
														item
														xs={12}
														lg={6}
														style={{ display: 'flex', alignItems: 'center' }}>
														<div
															className={classes.root}
															style={{ width: '100%' }}>
															<span
																style={{
																	color: 'black',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Name
															</span>
															<Field
																component={TextField}
																name='name'
																type='text'
																// error={errors.last_nameA1}
																variant='outlined'
																// label="Enter Last Name"
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: {
																		padding: 10,
																		border: '1px solid grey',
																		borderRadius: 5,
																	},
																}}
															/>
														</div>
													</Grid>
													<Grid
														item
														xs={12}
														lg={6}
														style={{ display: 'flex', alignItems: 'center' }}>
														<div
															className={classes.root}
															style={{ width: '100%' }}>
															<span
																style={{
																	color: 'black',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Email
															</span>
															<Field
																component={TextField}
																name='email'
																type='email'
																// error={errors.last_nameA1}
																variant='outlined'
																// label="Enter Last Name"
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: {
																		padding: 10,
																		border: '1px solid grey',
																		borderRadius: 5,
																	},
																}}
															/>
														</div>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12} md={12} lg={4}>
												<Grid container alignItems='center' spacing={2}>
													<Grid
														item
														xs={12}
														lg={6}
														style={{ display: 'flex', alignItems: 'center' }}>
														<div
															className={classes.root}
															style={{ width: '100%' }}>
															<span
																style={{
																	color: 'black',
																	fontSize: matches ? 12 : '.8em',
																}}>
																Mobile
															</span>
															<Field
																component={TextField}
																name='mobile'
																type='number'
																// error={errors.last_nameA1}
																variant='outlined'
																// label="Enter Last Name"
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: {
																		padding: 10,
																		border: '1px solid grey',
																		borderRadius: 5,
																	},
																}}
															/>
														</div>
													</Grid>
													<Grid
														item
														xs={12}
														lg={6}
														style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: 5,
														}}>
														<Box
															onClick={submitForm}
															className='btn'
															style={{
																width: '100%',
																fontSize: '16px',
																borderRadius: 4,
																backgroundColor: 'rgb(0, 55, 114)',
																padding: 8,
																textAlign: 'center',
															}}>
															<span>Submit</span>
														</Box>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Form>
								</>
							)}
						</Formik>
					</>
				)}
			</Formik>
		</>
	);
};

export default SearchEngineForex;
