import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	Tabs,
	Tab,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
// import { loginDispatch } from '../reducers/UsersReducer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SuccessModal from '../components/reusableComponents/SuccessModal';
import ErrorModal from '../components/reusableComponents/ErrorModal';
import {
	agentSignupDispatch,
	travellerSignupDispatch,
} from '../reducers/UserReducer';
import RegisterAgentModal from '../components/reusableComponents/RegisterAgentModal';
// import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		// marginBottom: 20,
		width: '100%',
		// marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		// padding: 14,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
// const country = ['INDIA', 'SRILANKA'];
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['Jharkhand', 'Kolkata'];
const title = ['Mr', 'Mrs'];
const RegisterAgentForm = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const { travellermodal } = useSelector((state) => state.ui);
	const [currentStep, SetCurrentStep] = useState('Step 1');

	const handleFormSubmit = (data, actions) => {
		console.log(data);
		if (travellermodal) {
			dispatch(travellerSignupDispatch(data, history));
		} else {
			dispatch(agentSignupDispatch(data, history));
			// dispatch(RegisterAgentModal(false))
		}
	};

	return (
		<>
			<Formik
				initialValues={{}}
				validate={(values) => {
					const errors = {};

					// if (!values.currentpassword) {
					// 	errors.currentpassword = 'Required';
					// }
					// if (!values.newpassword) {
					// 	errors.newpassword = 'Required';
					// }
					// if (!values.confirmnewpassword) {
					// 	errors.confirmnewpassword = 'Required';
					// }

					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20 }}>
								<Grid item xs={12} lg={4}>
									<h5>Title</h5>
									<Field
										component={TextField}
										select
										name='userTitle'
										type='text'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}>
										{title.map((item, index) => (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={12} lg={4}>
									<h5>First Name</h5>
									<Field
										component={TextField}
										name='userFirstName'
										type='text'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={4}>
									<h5>Last Name</h5>
									<Field
										component={TextField}
										name='userLastName'
										type='text'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} lg={6}>
									<h5>Phone</h5>
									<Field
										component={TextField}
										name='userPhone'
										type='number'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<h5>Email</h5>
									<Field
										component={TextField}
										name='userEmail'
										type='email'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} lg={6}>
									<h5>Password</h5>
									<Field
										component={TextField}
										name='password'
										type='password'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<h5>Confirm Password</h5>
									<Field
										component={TextField}
										name='confirmpassword'
										type='password'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>
							<Grid container style={{ marginTop: 20 }}>
								<Grid item xs={2}></Grid>
								<Grid item xs={8}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5, fontSize: 14 }}
										// disabled={isSubmitting}
									>
										Register
									</Button>
								</Grid>
								<Grid item xs={2}></Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default RegisterAgentForm;
