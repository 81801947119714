import './App.css';
import MainRouter from './components/MainRouter';
import { Provider } from 'react-redux';
import store from './Store';
import { HelmetProvider } from 'react-helmet-async';


function App() {
	return (
		<>
			<Provider store={store}>
			<HelmetProvider>
				<MainRouter />
				</HelmetProvider>
			</Provider>
		</>
	);
}

export default App;
