import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Button,
	Box,
	Grid,
	LinearProgress,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SuccessModal from '../components/reusableComponents/SuccessModal';
import ErrorModal from '../components/reusableComponents/ErrorModal';
import {
	agentloginDispatch,
	agentSignupDispatch,
	forgotPasswordDispatch,
} from '../reducers/UserReducer';
import LoadingModal from '../components/reusableComponents/LoadingModal';
import { setTabValue } from '../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		backgroundColor: 'white',
		marginBottom: 5,
		width: '100%',
		borderRadius: 25,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 5,
			// marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
			marginBottom: 10,
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#ee8622',
		color: 'white',
		borderRadius: 2,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#ee8622',
		},
	},
}));
const LoginB2B = () => {
	const classes = useStyles();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const { travellermodal, holidaySelect } = useSelector((state) => state.ui);
	const { loading } = useSelector((state) => state.home);
	const [currentStep, SetCurrentStep] = useState('Step 1');
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const handleFormSubmit = (data, actions) => {
		if (tabvalue === 0) {
			let formData = {
				username: data.userEmail,
				password: data.password,
			};
			console.log(formData);
			actions.setSubmitting(true);
			dispatch(agentloginDispatch(formData, history, actions));
		} else if (tabvalue === 1) {
			let formData = {
				userTitle: 'Mr',
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhone: data.userPhone,
				userEmail: data.userEmail,
				agencyName: data.agencyName,
				agencyPanCard: data.agencyPanCard,
				password: data.password,
				confirmpassword: data.password,
			};
			console.log('formData', formData);
			dispatch(agentSignupDispatch(formData, history, actions, setValue));
		} else {
			let formData = {
				userEmail: data.email,
			};
			console.log('Forqot Password', formData);
			dispatch(forgotPasswordDispatch(formData, history, actions, setValue));
			// dispatch(forgotPasswordDispatch)
		}
		// dispatch(flightHolidayEnquiry(formData));
	};

	return (
		<>
			<Formik
				initialValues={{
					userFirstName: '',
					userLastName: '',
					userEmail: '',
					userPhone: '',
					agencyName: '',
					agencyPanCard: '',
					password: '',
					confirmpassword: '',
				}}
				validationSchema={
					tabvalue === 1
						? Yup.object({
								userFirstName: Yup.string().required('firstname required'),
								userPhone: Yup.string()
									.required('Phone required')
									.matches(phoneRegExp, 'Phone number is not valid')
									.min(10, 'too short')
									.max(10, 'too long'),
								userLastName: Yup.string().required('lastname required'),
								agencyName: Yup.string().required('agencyName required'),
								agencyPanCard: Yup.string().required('agencyPanCard required'),
								password: Yup.string().required('password required'),
								confirmpassword: Yup.string().required(
									'confirmpassword required'
								),
								userEmail: Yup.string()
									.required('email required')
									.email('Enter valid email'),
						  })
						: Yup.object({
								userEmail: Yup.string()
									.required('email required')
									.email('Enter valid email'),
								password: Yup.string().required('password required'),
						  })
				}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						<Form>
							<Grid container>
								<Grid item xs={2}></Grid>
								<Grid
									item
									xs={8}
									style={{
										background: 'white',
										padding: 20,
										boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
										borderRadius: 5,
										marginTop: 60,
									}}>
									<Grid container spacing={2} alignItems='center'>
										<Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
											<h2
												style={{
													fontFamily: 'cursive',
													fontSize: '40px',
													color: '#990000',
												}}>
												Arya Aviation
											</h2>
											<span style={{ fontFamily: 'cursive', fontSize: '23px' }}>
												Welcome!
											</span>
										</Grid>
									</Grid>
									{tabvalue === 0 && (
										<>
											<p
												style={{
													color: '#000',
													fontSize: 20,
													marginBottom: 20,
													marginTop: 10,
												}}>
												Login
											</p>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='userEmail'
												autocomplete='off'
												type='email'
												variant='outlined'
												label='Email'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												autocomplete='off'
												name='password'
												type='password'
												variant='outlined'
												label='Password'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											{isSubmitting && (
												<Box mt={1.5}>
													<LinearProgress />
												</Box>
											)}
											<Button
												className={classes.createButton}
												onClick={submitForm}
												style={{
													marginTop: 5,
													fontSize: 14,
													fontWeight: 'bold',
												}}
												disabled={isSubmitting}>
												{isSubmitting ? 'Please Wait...' : 'LOGIN'}
											</Button>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
													marginTop: 15,
												}}>
												<p
													style={{ cursor: 'pointer', fontSize: 12 }}
													onClick={async () => {
														await setValue(2);
														dispatch(setTabValue(2));
														// validateForm(values);
													}}>
													Forgot Password?
												</p>
												<p
													style={{ cursor: 'pointer', fontSize: 12 }}
													onClick={async () => {
														await setValue(1);
														dispatch(setTabValue(1));
														// validateForm(values);
													}}>
													Register Now
												</p>
											</Box>
										</>
									)}
									{tabvalue === 1 && (
										<>
											<p
												style={{
													color: '#000',
													fontSize: 20,
													marginBottom: 20,
													marginTop: 10,
												}}>
												Registration
											</p>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='userFirstName'
												autocomplete='off'
												type='text'
												variant='outlined'
												label='First Name'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='userLastName'
												autocomplete='off'
												type='text'
												variant='outlined'
												label='Last Name'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='userEmail'
												autocomplete='off'
												type='email'
												variant='outlined'
												label='Email'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='userPhone'
												autocomplete='off'
												type='number'
												variant='outlined'
												label='Phone'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='agencyName'
												autocomplete='off'
												type='text'
												variant='outlined'
												label='Agency Name'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>

											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='agencyPanCard'
												autocomplete='off'
												type='text'
												variant='outlined'
												label='Agency Pan Card'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='password'
												autocomplete='off'
												type='password'
												variant='outlined'
												label='Password'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='confirmpassword'
												autocomplete='off'
												type='password'
												variant='outlined'
												label='Confirm Password'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											{isSubmitting && (
												<Box mt={1.5}>
													<LinearProgress />
												</Box>
											)}
											<Button
												className={classes.createButton}
												onClick={submitForm}
												style={{ marginTop: 5, fontSize: 14 }}
												disabled={isSubmitting}>
												REGISTER
											</Button>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
													marginTop: 15,
												}}>
												<p
													style={{ cursor: 'pointer' }}
													onClick={async () => {
														await setValue(0);
														dispatch(setTabValue(0));
														// validateForm(values);
													}}>
													Already have an account? Log In
												</p>
											</Box>
										</>
									)}
									{tabvalue === 2 && (
										<>
											<p
												style={{
													color: '#000',
													fontSize: 18,
													marginBottom: 20,
													marginTop: 10,
												}}>
												Forgot Password
											</p>
											<Field
												// style={{ marginTop: 60 }}
												component={TextField}
												name='email'
												autocomplete='off'
												type='email'
												variant='outlined'
												label='Email'
												className={classes.selectField}
												InputProps={{
													className: classes.InputPropsStyle,
												}}
												inputProps={{
													style: { padding: 14, fontSize: 14 },
												}}
											/>
											<Button
												className={classes.createButton}
												onClick={submitForm}
												// onClick={() => history.push('/fd')}
												style={{
													marginTop: 5,
													fontSize: 12,
													fontWeight: 'bold',
												}}
												// disabled={isSubmitting}
											>
												FORGOT PASSWORD
											</Button>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
													marginTop: 15,
												}}>
												<p
													style={{ cursor: 'pointer', fontSize: 12 }}
													onClick={async () => {
														await setValue(0);
														dispatch(setTabValue(0));
														// validateForm(values);
													}}>
													Already have an account? Log In
												</p>
											</Box>
										</>
									)}
								</Grid>
								<Grid item xs={2}></Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
			<LoadingModal />
			{/* {loading ? <LoaderModal /> : ""} */}
		</>
	);
};

export default LoginB2B;
