import React, { useState, useEffect } from 'react';
import {
	Grid,
	Box,
	makeStyles,
	Popper,
	Hidden,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { fetchCityDispatch } from '../reducers/HomeReducer';
import { setSection } from '../reducers/UiReducer';
import SearchBus2 from './SearchBus2';
import HotelForm from './HotelForm';
import SearchEngineArya from './SearchEngineArya';
import SearchEngineForex from './SearchEngineForex';
const useStyles = makeStyles((theme) => ({
	root: {
		// "& .MuiFormControl-root": {
		//   "& div": {
		//     "& div": {
		//       display: "none",
		//     },
		//   },
		// },
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		// "& .MuiInput-underline:after": {
		//   borderBottom: "none",
		// },
		'& input': {
			fontSize: 26,
			fontWeight: 600,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		// "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot":
		//   {
		//     paddingRight: 0,
		//   },
		// '& div': {
		// 	'& div': {
		// 		'& div': {
		// 			paddingRight: 0,
		// 		},
		// 	},
		// },
		'& .MuiAutocomplete-endAdornment': {
			display: 'none',
		},
		'& .MuiAutocomplete-root': {
			padding: 0,
		},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'white',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'white',
			fontSize: '18px',
			fontWeight: 800,
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '14px',
			// fontWeight: 800,
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
				// '& img': {
				// 	background: 'rgb(239, 51, 57)',
				// },
			},
		},
	},
	// (1,3)
}));
const MSearchFlight = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { agentlogin } = useSelector((state) => state.user);
	const { tripInfo } = useSelector((state) => state.ui);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	// const [section, setSection] = useState('flight');
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [total, SetTotal] = useState(adult + child + infant);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const { origin, depart, OriginO, selectedOrigin, selectedDepart, section } =
		useSelector((state) => state.ui);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [age, setAge] = React.useState('oneway');

	const handleChange = (value) => {
		setAge(value);
		console.log(value);
	};
	const handleSection = (value) => {
		dispatch(setSection(value));
		// setSection(value);
	};

	const handleSubmit = (data) => {
		// console.log(data);
		let org = data.Origin;
		let des = data.Destination;
		let formData = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 1,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: selectedOrigin.airport_code,
					Destination: selectedDepart.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
				},
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		let formData2 = {
			AdultCount: adult,
			ChildCount: child,
			InfantCount: infant,
			DirectFlight: false,
			OneStopFlight: false,
			JourneyType: 2,
			PreferredAirlines: null,
			Segments: [
				{
					Origin: selectedOrigin.airport_code,
					Destination: selectedDepart.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: origin,
				},
				{
					Origin: selectedDepart.airport_code,
					Destination: selectedOrigin.airport_code,
					FlightCabinClass: 1,
					PreferredDepartureTime: depart,
				},
			],
			Sources: 'WEB',
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		if (tripInfo === 'oneway') {
			history.push({
				pathname: `/oneway/${selectedOrigin.airport_code}/${selectedDepart.airport_code}/${origin}`,
				state: {
					body: formData,
					OriginO: origin,
				},
			});
		} else {
			history.push({
				pathname:
					selectedOrigin.country_code === 'IN' &&
					selectedDepart.country_code === 'IN'
						? `/roundway/${selectedOrigin.airport_code}/${selectedDepart.airport_code}/${origin}/${selectedDepart.airport_code}/${selectedOrigin.airport_code}/${depart}`
						: '/international',
				state: { returnBody: formData2 },
			});
		}
	};
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		// console.log(value);
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchCityDispatch(cityData));
	};
	const handleChangeTrip = (value) => {
		// console.log(e.target.value);
		// dispatch(setTrip(value));
	};
	useEffect(() => {
		let cityData = {
			keywords: 'l',
			limits: 8,
		};
		dispatch(fetchCityDispatch(cityData));
	}, []);
	const PopperMy = function (props) {
		return (
			<Popper
				{...props}
				style={styles.popper}
				className={classes.popper}
				placement='bottom-start'
			/>
		);
	};

	const styles = (theme) => ({
		popper: {
			width: 'fit-content',
		},
	});
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form style={{ marginTop: matches ? 100 : 100 }}>
							<Grid container>
								{/* <Grid item xs={2}></Grid> */}
								<Grid item xs={12}>
									<Box display='flex'>
										<Box
											onClick={() => {
												handleSection('flight');
												history.push('/Flights-Booking');
											}}
											style={{
												background: section === 'flight' ? 'white' : '#003772',
												padding: 14,
												cursor: 'pointer',
											}}
											display='flex'
											alignItems='center'>
											<i
												class='fa-solid fa-plane'
												style={{
													color: section === 'flight' ? 'blue' : 'white',
													fontSize: matches ? 12 : '',
												}}></i>
											<span
												style={{
													color: section === 'flight' ? 'blue' : 'white',
													marginLeft: '12px',
													fontSize: matches ? '12px' : '.8em',
												}}>
												Flight
											</span>
										</Box>
										{/* <a href="https://www.marinersforex.com/bus-booking" target="_blank"> */}
										<Box
											onClick={() => {
												handleSection('bus');
												history.push('/bus-booking');
											}}
											style={{
												background: section === 'bus' ? 'white' : '#003772',
												padding: 14,
												cursor: 'pointer',
											}}
											display='flex'
											alignItems='center'>
											<i
												class='fa-solid fa-bus'
												style={{
													color: section === 'bus' ? 'blue' : 'white',
													fontSize: matches ? 12 : '',
												}}></i>
											<span
												style={{
													color: section === 'bus' ? 'blue' : 'white',
													marginLeft: '12px',
													fontSize: matches ? 12 : '.8em',
													// fontSize: "20px",
												}}>
												Bus
											</span>
										</Box>
										<Box
											onClick={() => {
												handleSection('hotel');
												history.push('/hotel-booking');
											}}
											style={{
												background: section === 'hotel' ? 'white' : '#003772',
												padding: 14,
												cursor: 'pointer',
											}}
											display='flex'
											alignItems='center'>
											<i
												class='fa-solid fa-hotel'
												style={{
													color: section === 'hotel' ? 'blue' : 'white',
												}}></i>
											<span
												style={{
													color: section === 'hotel' ? 'blue' : 'white',
													marginLeft: '12px',
													fontSize: matches ? 12 : '.8em',
													// fontSize: "20px",
												}}>
												Hotel
											</span>
										</Box>
										{/* </a> */}
										<Hidden only={['xs']}>
											{/* <a
												href='https://www.marinersforex.com/hotel-booking'
												target='_blank'> */}
											{/* <Box
												onClick={() => handleSection('hotel')}
												style={{
													background: section === 'hotel' ? 'white' : '#003772',
													padding: 14,
													cursor: 'pointer',
												}}
												display='flex'
												alignItems='center'>
												<i
													class='fa-solid fa-hotel'
													style={{
														color: section === 'hotel' ? 'blue' : 'white',
													}}></i>
												<span
													style={{
														color: section === 'hotel' ? 'blue' : 'white',
														marginLeft: '12px',
														fontSize: '.8em',
														// fontSize: "20px",
													}}>
													{t('ht')}
												</span>
											</Box> */}
											{/* </a> */}
											
												<Box
													onClick={() => {handleSection("Forex");history.push('/forex');}}
													style={{
														background:
															section === 'Forex' ? 'white' : '#003772',
														padding: 14,
														cursor: 'pointer',
													}}
													display='flex'
													alignItems='center'>
													<i
														class='fas fa-file-invoice-dollar'
														style={{
															color: section === 'Forex' ? 'blue' : 'white',
														}}></i>
													<span
														style={{
															color: section === 'Forex' ? 'blue' : 'white',
															marginLeft: '12px',
															fontSize: '.8em',
														}}>
														Forex
													</span>
												</Box>
												{/* <a
												href='https://www.marinersforex.com/holiday-packages.php'
												target='_blank'>
												<Box
													style={{
														background:
															section === 'holiday' ? 'white' : '#003772',
														padding: 14,
														cursor: 'pointer',
													}}
													display='flex'
													alignItems='center'>
													<i
														class='fas fa-umbrella-beach'
														style={{
															color: section === 'holiday' ? 'blue' : 'white',
														}}></i>
													<span
														style={{
															color: section === 'holiday' ? 'blue' : 'white',
															marginLeft: '12px',
															fontSize: '.8em',
														}}>
														Holiday
													</span>
												</Box>
											</a> */}
										</Hidden>
									</Box>
								</Grid>
							</Grid>
							{section === 'flight' ? (
								<>
									<SearchEngineArya />
								</>
							) : section === 'bus' ? (
								<>
									<div
										style={{
											background: '#fff',
											borderRadius: '0.25rem',
											padding: '1.25rem 1.875rem',
											boxShadow: '0 0 57px rgb(0 0 0 / 20%)',
										}}>
										<SearchBus2 />
									</div>
								</>
							) : section === 'hotel' ? (
								<>
									<div
										style={{
											background: '#fff',
											borderRadius: '0.25rem',
											padding: '1.25rem 1.875rem',
											boxShadow: '0 0 57px rgb(0 0 0 / 20%)',
											// marginTop:matches?'':20
										}}>
										<HotelForm />
									</div>
								</>
							) :section ==='Forex'?(
								<>
									<SearchEngineForex/>
								</>
							):''}
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default MSearchFlight;
