import React, { useEffect, useState } from 'react';
import { Grid, Box, Hidden } from '@material-ui/core';
import './Homepage2.css';
import arya from '../../assets/images/landing.jpg';
import AA from '../../assets/images/AA.jpg';
import TitleText from '../../components/reusableComponents/TitleText';
import LoginB2B from '../../form/LoginB2B';
import RegisterAgentModal from '../../components/reusableComponents/RegisterAgentModal';
import Footer from './Footer';
import { useSelector } from 'react-redux';
const B2BHome = () => {
	const { tabValue } = useSelector((state) => state.ui);
	return (
		<>
			<nav
				className='navbar'
				style={{
					background: 'white',
					padding: 0,
					paddingTop: 8,
					position: 'fixed',
					width: '100%',
					zIndex: 999,
				}}>
				<div className='container flex'>
					<a href='' className='site-brand'>
						<img src={AA} style={{ height: 50, marginBottom: 5 }}></img>
						<span
							style={{
								color: 'rgba(139, 0, 0, 0.95)',
								fontSize: '30px',
								fontWeight: 600,
								fontFamily: 'cursive',
							}}>
							Arya Aviation
						</span>
					</a>

					<Hidden only={['xs', 'sm']}>
						<Box display='flex' alignItems='center'>
							<TitleText textStyle={{ fontSize: 18 }}>
								24x7 Agent Helpline
							</TitleText>
							<i
								class='fas fa-phone'
								style={{
									transform: 'rotate(90deg)',
									color: '#ef3339',
									fontSize: '28px',
									marginLeft: 20,
								}}></i>
							<Box onClick={() => (window.location = 'tel:887-777-3388')}>
								<TitleText
									textStyle={{
										marginLeft: 10,
										fontSize: 18,
										cursor: 'pointer',
									}}>
									+91 887-777-3388
								</TitleText>
							</Box>
						</Box>
					</Hidden>
				</div>
			</nav>
			<header
				className='flex'
				style={{
					backgroundImage: `url(${arya})`,
					zIndex: 0,
					transition: 'background-image 300ms ease-in 200ms',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center center',
					backgroundAttachment: 'fixed',
					height: tabValue === 1 ? '1000px' : '750px',
					backgroundPosition: 350,
				}}>
				<Grid container alignItems='center'>
					<Grid item xs={12} md={1}></Grid>
					<Grid item xs={12} md={5}>
						<h4
							style={{
								fontSize: 60,
								fontWeight: 'bold',
								color: 'rgba(139, 0, 0, 0.95)',
							}}>
							Welcome to
						</h4>
						<h4
							style={{
								fontSize: 24,
								fontSize: 60,
								fontFamily: 'monospace',
							}}>
							Arya Aviation
						</h4>
						<h4 style={{ fontSize: 44, color: 'slategrey' }}>
							A B2B Travel Portal Built Exclusively for Our Travel Partners
						</h4>
					</Grid>
					<Grid item xs={12} md={5}>
						<LoginB2B />
					</Grid>
					<Grid item xs={12} md={1}></Grid>
				</Grid>
			</header>
			<Footer />
			<RegisterAgentModal />
		</>
	);
};

export default B2BHome;
